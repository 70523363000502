import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './MyEditor.css'

function MyEditor({ value, handleMsg }) {
    const editorConfig = {
        toolbar: {
            items: ["bulletedList", "bold", "italic", "image", "link"],
            typing: {
                autoNumbering: "bulleted",
            },
        },
        link : {
            defaultProtocol: 'https://',
            addTargetToExternalLinks: true,
            decorators: [
             {
                label: 'External Link',
                attributes: {
                 target: '_blank',
                }
             }
            ]
        }
    };
    return (
        <CKEditor
            data={value ? value : ""}
            editor={ClassicEditor}
            config={editorConfig}
            onChange={(event, editor) => {
                const data = editor.getData();
                handleMsg(data)
            }}
        />
    );
}

export default MyEditor;
