import axios from "axios";


export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,

});
export const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //   headers:{'Authorization':"Bearer "+JSON.parse(localStorage.getItem("access_token"))?.data}
});

export const useAxiosInterceptor = () =>{
  api.interceptors.response.use(
    (response)=>{
      return response;
    },
    (error)=>{
      console.log("errorerrorerrorerrorerror",error)
    }
  )
}