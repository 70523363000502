import React, { useState } from 'react'
import USERS from '../../../common/data/userDummyData'
import Icon from '../../../components/icon/Icon'
import { DisabledVisible } from '../../../components/icon/material-icons'
import HelloGif from '../../../assets/img/waving_hand.gif'
import chatLoader from '../../../assets/img/chat-loader.gif'

const ChatData = ({ setChat, position, color, handleSample, setHandleSample }) => {
    const [MessageLoader, setMessageLoader] = useState(false)

    const getClass = () => {
        if (position === "BL") {
            return "bl"
        }
        if (position === "VRC") {
            return "vrc"
        }
        if (position === "BR") {
            return "br"
        }
        return "br"
    }

    const handleSampleBox = () => {
        setHandleSample(true)
        setTimeout(() => {
            setMessageLoader(true)
        }, 2000)
    }
    return (
        <div className={`chat-modal-open ${getClass()} `}>
            <div className='close-chat' onClick={() => setChat(false)}><Icon icon="Close" /></div>

            <div className='chat-block'>
                <div className='welcome-block'>
                    <div className='user-avatar'>
                        <img
                            srcSet={USERS.JOHN.srcSet}
                            src={USERS.JOHN.src}
                            alt='Avatar'
                            width={128}
                            height={128}
                        />
                    </div>
                    <div className='welcome-text'>
                        <div className='img-wrp'>
                            <img src={HelloGif} alt="hello" />
                        </div>
                        <p>What are you looking for?</p>
                        <div className='sample-text' style={{ borderColor: color, color: color }} onClick={handleSampleBox} >
                            Sample Option
                        </div>
                    </div>
                </div>                {
                    handleSample &&
                    <div className='main-chat'>
                        <div className='msz-block outgoing'>
                            <div className='user-avatar'>
                                <img
                                    srcSet={USERS.JOHN.srcSet}
                                    src={USERS.JOHN.src}
                                    alt='Avatar'
                                    width={128}
                                    height={128}
                                />
                            </div>
                            <div className='msz-text' style={{ backgroundColor: color }}><p>Hello vj</p></div>
                        </div>
                        {MessageLoader ?
                            <div className='msz-block incoming'>
                                <div className='user-avatar'>
                                    <img
                                        srcSet={USERS.JOHN.srcSet}
                                        src={USERS.JOHN.src}
                                        alt='Avatar'
                                        width={128}
                                        height={128}
                                    />
                                </div>
                                <div className='msz-text' style={{ backgroundColor: color }}><p>Hi Alex</p></div>
                            </div> :
                            <div className='incoming-loader-img'> <img src={chatLoader} alt="loader" /> </div>}
                    </div>}
            </div>
        </div >
    )
}

export default ChatData