import React from 'react'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/bootstrap/Button'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../components/bootstrap/Modal'
import { OffCanvasTitle } from '../../components/bootstrap/OffCanvas'
import { handleDeleteAction } from '../../Store/Action'

const DeleteModal = ({ currentPage, perPage, setDeletePopup, deletePopup }) => {
    const dispatch = useDispatch()

    const loginData = useSelector(state => state?.projectsReducer?.loginData);
    //add-manage-site
    const notify = (message) => toast(message, {
        style: {
            background: 'green',
            color: '#fff'
        }
    })
    const deleteData = () => {
        handleDeleteAction(dispatch, deletePopup, loginData?.result?.company_id, setDeletePopup, notify, currentPage, perPage,)
    }
    return (
        <div className='modal-wrap-role'>
            <Modal
                setIsOpen={setDeletePopup}
                isOpen={deletePopup}
                titleId='upcomingEdit'
                isCentered
                isScrollable
                id='manage-site-wrp'
                className='screenshot-modal'
            >
                <ModalHeader setIsOpen={setDeletePopup}>
                    <OffCanvasTitle id='upcomingEdit'> </OffCanvasTitle>
                </ModalHeader>
                <ModalBody>
                    <h3>Are you sure you want to delete?</h3>

                    {/* <div className='role-btn-wrp'>
                        <Button
                            icon='Cancel'
                            color='danger'
                            isLight
                            onClick={() => (setDeletePopup())}
                            className="btn-modal"
                        >
                            No
                        </Button>
                        <Button
                            icon='Save'
                            color='info'
                            isLight
                            onClick={deleteData}
                            className="btn-modal"
                        >
                            Yes
                        </Button>

                    </div> */}
                    <div className='role-btn-wrp'>
                        <ModalFooter>
                            <Button color='info' onClick={() => (setDeletePopup())} className="btn-modal">
                                No
                            </Button>
                            <Button color='info' onClick={deleteData} className="btn-modal yes-Btn">
                                Yes
                            </Button>
                        </ModalFooter>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default DeleteModal