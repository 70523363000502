import React, { useEffect, useState } from 'react';
import Card from '../../../components/bootstrap/Card';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AvtarSelect from './AvtarSelect';
import Position from './Position';
import Theme from './Theme';
import ChatData from './ChatData';
import Welcome from './Welcome';
import WelcomeTheme from './WelcomeTheme/WelcomeTheme';
import Button from '../../../components/bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	designAction,
	designEditAction,
	domainuiIdAction,
	getAvatarListAction,
	getDesignData,
} from '../../../Store/Action';
import { Link } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import ManageImage from './WelcomeTheme/ManageImage';

const Design = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const loginData = useSelector((state) => state?.projectsReducer?.loginData);
	const avatarList = useSelector((state) => state?.projectsReducer?.avatarList);
	const getDesign = useSelector((state) => state?.projectsReducer?.getDesign);
	const [hexaColorCode, setHexaColorCode] = useState();
	const [hexaSelect, setHexaSelect] = useState(false);
	const [selectedColor, setSelectedColor] = useState(null);
	const [checkMark, setCheckMark] = useState(false);

	const [position, setPosition] = useState('BL');
	const [avatar, setAvatar] = useState();
	const [color, setColor] = useState('#DE3163');
	const [chat, setChat] = useState(false);
	const [welcomeMessage, setWelcomeMessage] = useState(true);
	const [handleSample, setHandleSample] = useState(false);
	const [customWelcomeText, setCustomWelcomeText] = useState('Hello User !');
	const [customWelcomeImageId, setCustomWelcomeImageId] = useState();

	// upload top avtar image
	const [imgUpload, setImgUpload] = useState();

	// upload Bottom avtar image
	const [customPreviewUpload, setCustomPreviewUpload] = useState();
	const [preview, setPreview] = useState();
	const [customPreview, setCustomPreview] = useState();
	const [avatarRight, setAvatarRight] = useState();
	const [customAvatarRight, setCustomAvatarRight] = useState();
	const [chatTitle, setChatTitle] = useState('');
	const [uploadImg, setUploadImg] = useState();

	const { id } = useParams();

	useEffect(() => {
		return (() => {
			setChatTitle("")
		})
	}, [])

	const titleHandler = (e) => {
		setChatTitle(e.target.value);
	};

	const handleSubmit = (website_id) => {
		let payload = new FormData();

		// Check if hexaColorCode has a value, otherwise use color
		const selectedColor = hexaColorCode ? hexaColorCode : color;

		payload.append('position', position);
		payload.append('theme', color);
		// payload.append('theme', selectedColor);
		payload.append('welcomeMessage', welcomeMessage ? 1 : 0);
		payload.append('customWelcomeText', customWelcomeText);
		payload.append('chatTitle', chatTitle);

		// upload top avtar image
		if (imgUpload) {
			payload.append('avatar_image', imgUpload);
			payload.append('avatarId', '');
		} else {
			payload.append('avatarId', avatar);
			payload.append('avatar_image', '');
		}

		// upload bottom avtar image
		if (customPreviewUpload) {
			payload.append('customWelcomeImage', customPreviewUpload);
			payload.append('customPreviewUpload', '');
		} else {
			payload.append('customWelcomeIdimg_id', customWelcomeImageId);
			payload.append('customPreviewUpload', '');
		}

		if (id) {
			designEditAction(
				dispatch,
				payload,
				loginData?.result?.company_id,
				id,
				callback,
				navigate,
				website_id,
			);
		} else {
			designAction(dispatch, payload, loginData?.result?.company_id, navigate, website_id);
		}
	};

	const callback = () => {
		setAvatar();
		setCustomWelcomeImageId();
		setImgUpload();
		setCustomPreviewUpload();
		setCustomPreview();
		setPreview();
	};

	const handleWelcome = () => {
		setWelcomeMessage(true);
	};
	const getClass = () => {
		if (position === 'BL') {
			return 'bl';
		}
		if (position === 'VRC') {
			return 'vrc';
		}
		if (position === 'BR') {
			return 'br';
		}
		return 'br';
	};
	const handleCheckboxChange = () => {
		setWelcomeMessage(!welcomeMessage);
	};

	useEffect(() => {
		setChatTitle(getDesign?.result?.chatTitle);
	}, [getDesign]);

	useEffect(() => {
		getAvatarListAction(dispatch, loginData?.result?.company_id);
	}, [loginData]);

	//getDatawithId
	useEffect(() => {
		if (id) {
			getDesignData(dispatch, loginData?.result?.company_id, id);
		}
	}, [avatarList]);

	useEffect(() => {
		let temp = {};
		avatarList?.result?.forEach((item) => {
			if (`${item.id}` === `${avatar}`) {
				temp = item;
			}
		});
		if (temp?.avatar_img) {
			setAvatarRight(`data:image/png;base64,${temp?.avatar_img}`);
		}
	}, [avatarList, avatar]);

	useEffect(() => {
		if (customWelcomeImageId) {
			let temp = {};
			avatarList?.result?.forEach((item) => {
				if (`${item.id}` === `${customWelcomeImageId}`) {
					temp = item;
				}
			});
			setCustomAvatarRight(`data:image/png;base64,${temp?.avatar_img}`);
		}
	}, [avatarList, customWelcomeImageId]);

	useEffect(() => {
		if (avatarList && getDesign && getDesign?.result) {
			if (
				!(getDesign?.result?.avatar?.length > 0) &&
				!(getDesign?.result?.avatarId?.length > 0)
			) {
				setAvatar(avatarList?.result?.[0]?.id);
			}
			if (
				!getDesign?.result?.customWelcomeImage &&
				!getDesign?.result?.customWelcomeIdimg_id
			) {
				setCustomWelcomeImageId(avatarList?.result?.[3]?.id);
			}
		}
	}, [avatarList, getDesign]);

	//setDesign
	useEffect(() => {
		if (getDesign?.result) {
			if (getDesign?.result?.position) {
				setPosition(getDesign?.result?.position);
			}

			if (getDesign?.result?.avatar) {
				setPreview(`data:image/png;base64,${getDesign?.result?.avatar}`);
				setAvatarRight(`data:image/png;base64,${getDesign?.result?.avatar}`);
				setImgUpload(`data:image/png;base64,${getDesign?.result?.avatar}`);
			} else {
				if (getDesign?.result?.avatarId) {
					setAvatar(getDesign?.result?.avatarId);
				}
			}

			if (getDesign?.result?.theme) {
				setColor(getDesign?.result?.theme);
				setHexaColorCode(getDesign?.result?.theme);
			}
			if (getDesign?.result?.customWelcomeImage) {
				setCustomPreview(`data:image/png;base64,${getDesign?.result?.customWelcomeImage}`);
				setCustomAvatarRight(
					`data:image/png;base64,${getDesign?.result?.customWelcomeImage}`,
				);
				setCustomPreviewUpload(getDesign?.result?.customWelcomeImage);
			}

			setWelcomeMessage(getDesign?.result?.welcomeMessage ? true : false);

			if (getDesign?.result?.customWelcomeIdimg_id) {
				setCustomWelcomeImageId(getDesign?.result?.customWelcomeIdimg_id);
			}
			if (getDesign?.result?.customWelcomeText) {
				setCustomWelcomeText(getDesign?.result?.customWelcomeText);
			}
		}
	}, [getDesign]);

	const handleHexChange = (color) => {
		setColor();
		setHexaColorCode(color.hex);
		setSelectedColor(color.hex);
		// setHexaSelect(false)
	};

	const customhexaColorHandler = () => {
		setHexaSelect(!hexaSelect);
		setSelectedColor(color);
		setHexaColorCode(color);
	};


	return (
		<PageWrapper title={'Design'} className='design-page'>
			<Page>
				<Card stretch data-tour='list' className='design-card'>
					<div className='row design-new'>
						<div className='design-left col-4'>
							<Card>
								<Position setPosition={setPosition} position={position} />

								<div className='title-block'>
									<h3>Title</h3>
									<input
										type='text'
										placeholder='Please Enter Title...'
										onChange={titleHandler}
										value={chatTitle ? chatTitle : ""}
										className='input-chat-title'
									/>
								</div>

								{/* Top Avatar logo */}
								<AvtarSelect
									setAvatar={setAvatar}
									avatar={avatar}
									avatarList={avatarList}
									setImgUpload={setImgUpload}
									preview={preview}
									setPreview={setPreview}
									setAvatarRight={setAvatarRight}
									company_id={loginData?.result?.company_id}
									setUploadImg={setUploadImg}
								/>

								<ManageImage setCustomAvatarRight={setCustomAvatarRight} setCustomPreviewUpload={setCustomPreviewUpload} setCustomWelcomeImageId={setCustomWelcomeImageId} customWelcomeImageId={customWelcomeImageId} avatarList={avatarList} setCustomPreview={setCustomPreview} customPreview={customPreview} />


								<Theme
									setColor={setColor}
									color={color}
									hexaSelect={hexaSelect}
									setHexaSelect={setHexaSelect}
									hexaColorCode={hexaColorCode}
									setHexaColorCode={setHexaColorCode}
									selectedColor={selectedColor}
									setSelectedColor={setSelectedColor}
									checkMark={checkMark}
									setCheckMark={setCheckMark}
								/>

								{/* <div className='picker-wrap' style={{ marginBottom: '15px' }}>
									<div className='color-picker' onClick={customhexaColorHandler}>
										<i className='icon-pointer'></i>
									</div>
								</div> */}

								{/* {hexaSelect && (
									<div style={{ marginBottom: '15px' }}>
										<SketchPicker
											style={{ marginLeft: 20 }}
											onChange={handleHexChange}
											color={hexaColorCode}
										/>
									</div>
								)}  */}

								<div className='additional-data theme-head'>
									<div className='msz-wrp'>
										<h3>Welcome Message</h3>
										<label class='switch'>
											<input
												type='checkbox'
												checked={welcomeMessage}
												onChange={handleCheckboxChange}
											/>
											<span class='slider round'></span>
										</label>
									</div>

									{welcomeMessage && (
										<div className='welcome-msz-theme'>
											<WelcomeTheme
												setCustomAvatarRight={setCustomAvatarRight}
												setCustomPreviewUpload={setCustomPreviewUpload}
												customWelcomeImageId={customWelcomeImageId}
												setCustomWelcomeText={setCustomWelcomeText}
												setCustomWelcomeImageId={setCustomWelcomeImageId}
												customWelcomeText={customWelcomeText}
												avatarList={avatarList}
												customPreview={customPreview}
												setCustomPreview={setCustomPreview}
											/>
										</div>
									)}
								</div>
							</Card>
						</div>
						<div className='design-right col-8'>
							<div className='btn-wrap'>
								{/* <Button
                                    color='info'
                                    icon='Preview'
                                    isLight
                                    className="btn preview"
                                >
                                    Preview
                                </Button> */}

								{/* ========================================================== New Change-====================================== */}
								{/* <Link to={`/train-modal/${id}`}
                                    color='info'
                                    icon='Message'
                                    isLight
                                    className="trend-btn"
                                >
                                    Train Your Model 111111
                                </Link> */}

								{/* <Link
									to={`/train-modal/${getDesign?.result?.website_id}`}
									color='info'
									icon='Message'
									isLight
									className='trend-btn'>
									Train Your Model 111111
								</Link> */}

								{/* ========================================================== New Change-====================================== */}

								<Button
									color='info'
									icon='Save'
									isLight
									className='btn'
									onClick={() => handleSubmit(getDesign?.result?.website_id)}>
									Save and publish
								</Button>
							</div>
							<Card>
								<div className='dummy-box-wrap'>
									{welcomeMessage && (
										<Welcome
											// customAvatarRight={customAvatarRight}
											welcomeMessage={welcomeMessage}
											customWelcomeText={customWelcomeText}
											customWelcomeImageId={customWelcomeImageId}
											color={color}
											position={position}
											setHandleSample={setHandleSample}
											chat={chat}
											setChat={setChat}
											setWelcomeMessage={setWelcomeMessage}
											avatarList={avatarList}
											avatarRight={avatarRight}
										/>
									)}
									{chat && (
										<ChatData
											setChat={setChat}
											position={position}
											color={color}
											welcomeMessage={welcomeMessage}
											handleWelcome={handleWelcome}
											handleSample={handleSample}
											setHandleSample={setHandleSample}
										/>
									)}
									<div
										className={`dummy-box ${getClass()}`}
										// style={{ backgroundColor: color }}
										onClick={() => setChat(true)}>
										{/* <img src={avatarRight} alt='avatar' /> */}
										<img src={customAvatarRight} alt='avatar' />
									</div>
								</div>
							</Card>
						</div>
					</div>
				</Card>
			</Page>
		</PageWrapper>
	);
};

export default Design;
