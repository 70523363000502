import React, { useEffect, useState } from 'react'
import Button from '../../components/bootstrap/Button'
import avtar from '../../assets/img/human.svg';
import Icon from '../../components/icon/Icon';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { handleEditAction } from '../../Store/Action';

const schema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().required('Email is required').email('Invalid email'),
    plan_name: yup.string().required('Plan Name is required'),
    phone_number: yup
        .string()
        .required('Phone Number is required')
        .matches(/^[0-9]+$/, 'Phone Number must only contain digits')
        .min(10, 'Phone Number must be at least 10 digits')
        .max(10, 'Phone Number must be at most 10 digits'),
    start_date: yup.string().required('Start date is required'),
    expire_date: yup.string().required('Expire date is required'),
    renewal_date: yup.string().required('Renewal Date is required'),
    site_limit: yup.string().required('Site limit is required'),

});
const EditAccountDetail = ({ setOpenEditForm }) => {
    const [file, setFile] = useState();
    const [initialImage, setInitialImage] = useState()
    const company_deatil = useSelector(state => state?.projectsReducer?.companyDetail)
    const loginData = useSelector(state => state?.projectsReducer?.loginData)
    const dispatch = useDispatch()
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: company_deatil?.result?.name,
            email: company_deatil?.result?.handleSubmitemail,
            plan_name: company_deatil?.result?.plan_name,
            phone_number: company_deatil?.result?.phone_number,
            start_date: company_deatil?.result?.start_date,
            expire_date: company_deatil?.result?.expire_date,
            renewal_date: company_deatil?.result?.renewal_date,
            site_limit: company_deatil?.result?.site_limit,
        },
        validationSchema: schema,
    });

    useEffect(() => {
        setInitialImage(`data:image/png;base64,${company_deatil?.result?.company_logo}`)
    }, [company_deatil])

    // Handle form submission
    const onSubmit = (data) => {
        let payloadFinal = new FormData()

        if (file) {
            payloadFinal.append("image", file)
        }
        payloadFinal.append("email", data.email)
        payloadFinal.append("phone_number", data.phone_number)
        payloadFinal.append("plan_name", data.plan_name)
        payloadFinal.append("name", data.name)
        payloadFinal.append("expire_date", data.expire_date)
        payloadFinal.append("renewal_date", data.renewal_date)
        payloadFinal.append("site_limit", data.site_limit)
        payloadFinal.append("start_date", data.start_date)
        handleEditAction(dispatch, payloadFinal, loginData?.result?.company_id, setOpenEditForm)
    };

    console.log("company_deatil", company_deatil)

    const handleFile = (e) => {
        setFile(e.target.files[0])
    }

    return (
        <div className='account-detail'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='profile-icon'>
                    <img src={file ? URL.createObjectURL(file) : initialImage ? initialImage : avtar} alt="avtar" />
                    <input type="file" onChange={handleFile} accept=".png ,.jpg ,.jpeg" />
                    <i className='icon-edit'><Icon icon='edit' size='lg' /></i>
                </div>
                <div className='contact-information row'>
                    <div className='form-group col-6'>
                        <label>Company Name</label>
                        <input type="text" {...register('name')} className="form-control" />
                        {errors?.name && <p className='error'>{errors?.name.message}</p>}
                    </div>
                    <div className='form-group col-6'>
                        <label>Email Id</label>
                        <input type="text" {...register('email')} className="form-control" />
                        {errors?.email && <p className='error'>{errors?.email.message}</p>}
                    </div>
                    <div className='form-group col-6'>
                        <label>Phone Number</label>
                        <input type="text" {...register('phone_number')} className="form-control" />
                        {errors?.phone_number && <p className='error'>{errors?.phone_number.message}</p>}
                    </div>
                    <div className='form-group col-6'>
                        <label>Plan Name</label>
                        <input type="text" {...register('plan_name')} className="form-control" />
                        {errors?.plan_name && <p className='error'>{errors?.plan_name.message}</p>}
                    </div>
                    {/* <div className='form-group col-6'>
                        <label>Activation Date</label>
                        <input type="text" {...register('start_date')} className="form-control" />
                        {errors?.start_date && <p className='error'>{errors?.start_date.message}</p>}
                    </div> */}
                    {/* <div className='form-group col-6'>
                        <label>Expiry Date</label>
                        <input type="text" {...register('expire_date')} className="form-control" />
                        {errors?.expire_date && <p className='error'>{errors?.expire_date.message}</p>}
                    </div> */}
                    {/* <div className='form-group col-6 date-wrap'>
                        <label>Next Renewal Date</label>
                        <input type="text" {...register('renewal_date')} className="form-control" />
                        {errors?.renewal_date && <p className='error'>{errors.renewal_date.message}</p>}
                    </div> */}
                    {/* <div className='form-group col-6'>
                        <label>Site Limit</label>
                        <input type="text" {...register('site_limit')} className="form-control" />
                        {errors?.site_limit && <p className='error'>{errors.site_limit.message}</p>}
                    </div> */}
                    <div className='save-btn-wrap '>
                        <button className='btn' type="submit" >Save</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditAccountDetail