import React from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import './Transcript.scss';

function Transcript() {
    return (
        <PageWrapper>
            <div className='transcript'>
                <h1>Chatbot Session information</h1>

                <div className='transcript-info'>
                    {/* Message Transcript */}
                    <div className='message'>
                        <h4>Message Transcript</h4>
                    </div>
                    {/* Visitor Details */}
                    <div className='visitor'>
                        <h4>Visitor Details</h4>

                        <div className='visitor-details'>
                            <h5>Visitor Name</h5>
                            <p>Nixon@sparxit.com</p>
                        </div>

                        <div className='visitor-details'>
                            <h5>Email Address</h5>
                            <p>Nixon@sparxit.com</p>
                        </div>

                        <div className='visitor-details'>
                            <h5>Mobile Number</h5>
                            <p>7983004599</p>
                        </div>

                        <div className='visitor-details'>
                            <h5>Location</h5>
                            <p>Delhi, New Delhi, 110001, India</p>
                        </div>

                        <div className='visitor-details'>
                            <h5>Landing Page URL</h5>
                            <p>https://www.example.com/dummy</p>
                        </div>

                        <div className='visitor-details'>
                            <h5>Travel page</h5>
                            <p>https://www.example.com/dummy</p>
                        </div>

                        <div className='visitor-details'>
                            <h5>Other Sessions</h5>
                            <p>https://www.example.com/dummy</p>
                        </div>
                    </div>
                </div>

            </div>
        </PageWrapper>
    )
}

export default Transcript