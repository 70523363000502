// Define the module
function idleTimeDetector(timeInMinutes) {
    const minuteInMilliseconds = 60 * 1000;
    // Private and constants
    let idleTime = 0;
    let idleTimeout = timeInMinutes * minuteInMilliseconds;
    let idleInterval;
    let idleCallback;
  
    // Private function to reset idle time
    function resetIdleTime() {
      // console.log(`idleTime reset`);
      localStorage.setItem('idleStatus', '0');
      idleTime = 0;
    }
  
    // Private function to handle idle state
    function handleIdle() {
      if (idleCallback && idleCallback === 'function') {
        idleCallback(); // Invoke the callback function
      }
    }
  
    // Private function to check idle time
    function checkIdleTime() {
      //console.log('Aj time log', Math.random(), idleTime);
      if (idleTime > Number(localStorage.getItem('idleStatus'))) {
        idleTime = Number(localStorage.getItem('idleStatus')) + 1000;
      } else {
        idleTime += 1000;
      }
  
      localStorage.setItem('idleStatus', idleTime.toString());
      if (idleTime >= idleTimeout) {
        clearInterval(idleInterval);
        // console.log(`idleTimeDetector calling handleIdle callback`);
        handleIdle();
      }
    }
  
    // Public function to start tracking idle time
    function startTracking(callback) {
      idleCallback = callback;
      idleInterval = setInterval(
        checkIdleTime,
        //time updated to avoid rendering issue
        1000,
      );
      document.addEventListener('mousemove', resetIdleTime);
      document.addEventListener('keydown', resetIdleTime);
      document.addEventListener('scroll', resetIdleTime);
    }
  
    function stopTracking() {
      try {
        clearInterval(idleInterval);
        document.removeEventListener('mousemove', resetIdleTime);
        document.removeEventListener('keydown', resetIdleTime);
        document.removeEventListener('scroll', resetIdleTime);
      } catch (e) {
        // console.log(e);
      }
    }
  
    // Expose the public functions
    return {
      startTracking,
      stopTracking,
      handleIdle,
    };
  }
  
  export default idleTimeDetector;
  