import Icon from "../../../components/icon/Icon";

// These will be available from the sidebar
export const fields = [
    {
        type: "Message",
        title: "Message"
    },
    {
        type: "Select",
        title: "Select"
    },
    {
        type: "Email",
        title: "Email"
    },
    {
        type: "Phone",
        title: "Phone"
    },
    {
        type: "Thanks",
        title: "Thank You"
    },
    {
        type: "Ai",
        title: "AI Bot"
    },
];
// These define how we render the field
export const renderers = {

    input: () => (
        <div className='csk-data' >
            <h2>Hello</h2>
            <div className='csk-icon-btn'>
                <button className='btn edit' >
                    <Icon icon="edit" />
                </button>
                <button className='btn delete' >
                    <Icon icon="delete" />
                </button>
                <button className='btn copy'>
                    <Icon icon="FileCopy" />
                </button>
            </div>
        </div>
    ),
    textarea: () => (
        <div className='csk-data' >
            <h2>Enter Your Choice</h2>
            <div className='csk-icon-btn'>
                <button className='btn edit' >
                    <Icon icon="edit" />
                </button>
                <button className='btn delete' >
                    <Icon icon="delete" />
                </button>
                <button className='btn copy'>
                    <Icon icon="FileCopy" />
                </button>
            </div>
        </div>
    ),
    select: () => (
        <div className='csk-data' >
            <h2>Enter Your Choice</h2>
            <div className='csk-icon-btn'>
                <button className='btn edit' >
                    <Icon icon="edit" />
                </button>
                <button className='btn delete' >
                    <Icon icon="delete" />
                </button>
                <button className='btn copy'>
                    <Icon icon="FileCopy" />
                </button>
            </div>
        </div>
    ),
    text: () => (
        <div className='csk-data' >
            <h2>Enter Your Email</h2>
            <div className='csk-icon-btn'>
                <button className='btn edit' >
                    <Icon icon="edit" />
                </button>
                <button className='btn delete' >
                    <Icon icon="delete" />
                </button>
                <button className='btn copy'>
                    <Icon icon="FileCopy" />
                </button>
            </div>
        </div>
    ),
    button: () => (
        <div className='csk-data' >
            <h2>Enter Your Phone</h2>
            <div className='csk-icon-btn'>
                <button className='btn edit' >
                    <Icon icon="edit" />
                </button>
                <button className='btn delete' >
                    <Icon icon="delete" />
                </button>
                <button className='btn copy'>
                    <Icon icon="FileCopy" />
                </button>
            </div>

        </div>
    )


};
