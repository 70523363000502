import React, { useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import Icon from '../../../../components/icon/Icon';
// import uploadImage from '../../../../assets/img/uploadIcon.png';
import uploadIconImg from '../../../../assets/img/uploadIcon.png'

const ManageImage = ({
	setCustomAvatarRight,
	setCustomPreviewUpload,
	setCustomWelcomeImageId,
	customWelcomeImageId,
	avatarList,
	customPreview,
	setCustomPreview,
}) => {
	const handlePreview = (e) => {
		const file = e.target.files[0];
		if (file) {
			setCustomPreviewUpload(file);
			const imgUrl = URL.createObjectURL(file);
			setCustomWelcomeImageId();
			setCustomPreview(imgUrl);
			setCustomAvatarRight(imgUrl);
		}
	};
	const handleAvatar = (e) => {
		setCustomWelcomeImageId(e?.id);
		setCustomPreviewUpload();
		setCustomAvatarRight(`data:image/png;base64,${e?.avatar_img}`);
		setCustomPreview();
	};
	var settings = {
		dots: false,
		infinite: false,
		arrow: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					arrows: false,
					slidesToShow: 5,
				},
			},
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					slidesToShow: 3,
				},
			},
		],
	};


	return (
		<div className='avtar-wrap theme-head custom-welcome'>
			<h3>Avatar Image</h3>
			<div className='icon-field-wrp form-group-wrp'>
				<div className='project-icon-wrap'>
					<div className='slider-wrapper'>
						<Slider {...settings}>
							<div
								className={`upload-img-wrap icon-wrp gray-bg upload-project-icon ${
									customPreview ? 'active' : ''
								}`}>
								<div className='upload-img'>
									<img src={customPreview || uploadIconImg} alt='CustomPreview' />
									<input
										type='file'
										multiple
										accept='.jpg,.png,.jpeg'
										onChange={handlePreview}
									/>
								</div>
							</div>
							{avatarList?.result?.length > 0 &&
								avatarList?.result?.map((item, index) => {
									return (
										<div
											className={`avtar-img-wrpp icon-wrp ${
												customWelcomeImageId == item?.id ? 'active' : ''
											}`}
											key={item?.id}
											onClick={() => handleAvatar(item)}>
											<img
												src={`data:image/png;base64,${item?.avatar_img}`}
												alt='img'
											/>
										</div>
									);
								})}
						</Slider>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ManageImage;
