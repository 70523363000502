import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
// import Logo from '../../../assets/img/open-chat.png';
import Logo from '../../../assets/img/Final-logo.png';
import useDarkMode from '../../../hooks/useDarkMode';
import { api } from '../../../Api/Index';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction, signUpAction } from '../../../Store/Action';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const SignUp = ({ isSignUp }) => {
	const dispatch = useDispatch();
	// const loginDAta = useSelector((state) => state.projectsReducer.loginData);
	const { darkModeStatus } = useDarkMode();
	// const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	// // const [passWord, setPassWord] = useState();
	// const [surName, setSurName] = useState();

	const notify = (message) =>
		toast(message, {
			style: {
				background: 'green',
				color: '#fff',
			},
		});

	// ===================================================================================================

	const [userError, setUserError] = useState({
		// email: false,
		passwordLength: false,
		confirmPasswordLength: false,
		// userNameLength: false,
		emailLength: false,
		// companyLogoLength: false,
		nameLength: false,
		passwordMatch: false,
		emailInvalid: false,
		passwordValidation: false,
	});

	const [name, setName] = useState();
	const [email, setEmail] = useState();
	const [userName, setUserName] = useState();
	const [passWord, setPassWord] = useState();
	const [confirmPassWord, setConfirmPassWord] = useState('');

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const [confirmPassWordFocused, setConfirmPassWordFocused] = useState(false);
	// const [buttonClicked, setButtonClicked] = useState(false);

	// const [companyLogoFile, setCompanyLogoFile] = useState();

	// const nameHandler = (e) => {
	// 	const value = e.target.value;
	// 	setName(value);
	// 	setUserError({ ...userError, nameLength: true });
	// };

	const nameHandler = (e) => {
		const value = e.target.value;
		setName(value);
		setUserError({ ...userError, nameLength: value.trim() === '' });
	};

	const emailHandler = (e) => {
		const value = e.target.value;
		setEmail(value);
		setUserError({ ...userError, emailLength: value.trim() === '' });

		// Clear the email error when input changes
		if (userError.emailInvalid) {
			setUserError({ ...userError, emailInvalid: false });
		}
	};

	// const userNameHandler = (e) => {
	// 	const value = e.target.value;
	// 	setUserName(value);
	// 	setUserError({ ...userError, userNameLength: value.trim() === '' });
	// };

	const passWordHandler = (e) => {
		const value = e.target.value;
		setPassWord(value);
		setUserError({
			...userError,
			passwordLength: value.trim() === '',
			passwordValidation: false,
		});
	};


	const ConfirmPassWordHandler = (e) => {
		const value = e.target.value;
		setConfirmPassWord(value);
		// if (confirmPassWord && !confirmPassWordFocused) {
		// 	setUserError({ ...userError, confirmPasswordLength: value.trim() === '' });
		// }

		// if (confirmPassWord) {
		// 	setUserError({ ...userError, confirmPasswordLength: value.trim() === '' });
		// }

		if (!confirmPassWord) {
			setUserError({ ...userError, confirmPasswordLength: value.trim() === '' });
		} else {
			setUserError({ ...userError, confirmPasswordLength: false });
		}

		// const value = e.target.value;
		// setConfirmPassWord(value);

		if (passWord !== value) {
			setUserError({ ...userError, passwordMatch: true });
		} else {
			setUserError({ ...userError, passwordMatch: false });
		}

		// if (buttonClicked && value.trim() === '') {
		// 	setUserError({ ...userError, confirmPasswordLength: true });
		// } else {
		// 	setUserError({ ...userError, confirmPasswordLength: false });
		// }
	};

	const handleConfirmPasswordFocus = () => {
		setConfirmPassWordFocused(true);
	};

	const handleConfirmPasswordBlur = () => {
		setConfirmPassWordFocused(false);
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	// Function to toggle confirm password visibility
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	// const companyLogoHandler = (e) => {
	// 	const file = e.target.files[0];

	// 	if (!file) {
	// 		setUserError({ companyLogoLength: true });
	// 		setCompanyLogoFile(null);
	// 		return;
	// 	}

	// 	if (e.target.files.length > 1) {
	// 		setUserError({ companyLogoLength: true });
	// 		setCompanyLogoFile(null);
	// 		return;
	// 	}

	// 	setUserError({ companyLogoLength: false });
	// 	setCompanyLogoFile(file);
	// };


	const signUpHandler = () => {
		// setButtonClicked(true);
		// Define regex pattern for email validation
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		// const passwordPattern = '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,14}$';
		// const passwordPattern = '^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,14}$';
		const passwordPattern = '^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,14}$';


		const passwordRegex = new RegExp(passwordPattern);

		if (!passwordRegex.test(passWord)) {
			setUserError({ ...userError, passwordValidation: true });
			return;
		}

		if (!name || name.trim() === '') {
			setUserError({ ...userError, nameLength: true });
			return;
		}

		if (!email || email.trim() === '') {
			setUserError({ ...userError, emailLength: true });

			return;
		}

		if (!emailPattern.test(email)) {
			setUserError({ ...userError, emailInvalid: true });
			return;
		}

		// if (!userName || userName.trim() === '') {
		// 	setUserError({ ...userError, userNameLength: true });
		// 	return;
		// }

		// if (!companyLogoFile) {
		// 	setUserError({ ...userError, companyLogoLength: true });
		// 	return;
		// } else {
		// 	setUserError({ ...userError, companyLogoLength: false });
		// }

		if (!passWord || passWord.trim() === '') {
			setUserError({ ...userError, passwordLength: true });
			return;
		} else {
			setUserError({ ...userError, passwordLength: false });
		}

		// if (!confirmPassWord || confirmPassWord.trim() === '') {
		// 	setUserError({ ...userError, confirmPasswordLength: true });
		// 	return;
		// }

		// if (confirmPassWord?.length === 0) {
		// 	setUserError({ ...userError, confirmPasswordLength: true });
		// 	return;
		// }

		// if (!confirmPassWord  || passWord !== confirmPassWord) {
		// 	setUserError({ ...userError, passwordMatch: true });
		// 	return;
		// }


		if (passWord !== confirmPassWord) {
			setUserError({ ...userError, passwordMatch: true });
			return;
		}

		let payload = new FormData();
		payload.append('name', name);
		// payload.append('username', userName);
		payload.append('email', email);
		// payload.append('image', companyLogoFile);
		payload.append('password', passWord);
		payload.append('confirm_password', confirmPassWord);
		payload.append('address', '');
		payload.append('is_renew', 0);
		payload.append('start_date', '');
		payload.append('expire_date', '');

		signUpAction(dispatch, payload, navigate, notify);
	};

	useEffect(() => {
		setIsNewUser(isSignUp);
	}, [isSignUp]); 

	const navigate = useNavigate();
	// const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const LoginHeader = () => {
		return (
			<>
				<div className='text-center  continue-signin'>Register as a company</div>
			</>
		);
	};
	return (
		<PageWrapper
			title={isNewUser ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-signup': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark signup-main-wrap' data-tour='login-page'>
							<CardBody>
								<div className='text-center'>
									<Link
										to='/'
										className={classNames('text-decoration-none  fw-bold ', {
											'text-dark': !darkModeStatus,
											'text-light': darkModeStatus,
										})}>
										<div className='login-logo-wrp'>
											<img src={Logo} alt='logo' />
										</div>
									</Link>
								</div>
								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<label className='label'>Name</label>
											<FormGroup id='login-username'>
												<Input
													type='text'
													// autoComplete='comapanyName'
													className='form-control'
													onChange={(e) => nameHandler(e)}
													placeholder='Name'
												/>
											</FormGroup>
											{userError.nameLength && (
												<p className='error'> Please enter name</p>
											)}
										</div>

										<div className='col-12'>
											<label className='label'>Work Email ID</label>
											<FormGroup id='login-username'>
												<Input
													type='email'
													autoComplete='username'
													className='form-control'
													onChange={(e) => emailHandler(e)}
													placeholder='Work Email ID'
												/>
											</FormGroup>
											{userError.emailLength && (
												<p className='error'> Please enter email</p>
											)}

											{userError.emailInvalid && (
												<p className='error'>Please enter a valid email</p>
											)}
										</div>

										{/* ** WORK EMAIL ID ** */}

										{/* <div className='col-12'>
											<label className='label'>Work Email ID</label>
											<FormGroup id='login-username'>
												<Input
													type='email'
													autoComplete='username'
													className='form-control'
													onChange={(e) => emailHandler(e)}
													placeholder='Work Email ID'
												/>
											</FormGroup>

											{userError.emailLength && (
												<p className='error'> Please enter your work email id</p>
											)}

											{userError.emailInvalid && (
												<p className='error'>Please enter a valid work email id</p>
											)}
										</div> */}

										{/* <div className='col-12'>
												<label className='label'>Username</label>
												<FormGroup id='login-username'>
													<Input
														autoComplete='username'
														className='form-control'
														onChange={(e) => userNameHandler(e)}
													/>
												</FormGroup>
												{userError.userNameLength && (
													<p className='error'> Please enter userName</p>
												)}
											</div> */}

										{/* <div className='col-12'>
												<label className='label'>Company Logo</label>
												<FormGroup id='login-username'>
													<Input
														type='file'
														autoComplete='companyLogo'
														className='form-control'
														onChange={(e) => companyLogoHandler(e)}
													/>
												</FormGroup>
												{userError.companyLogoLength && (
													<p className='error'>
														Please upload only one image
													</p>
												)}

												{companyLogoFile && (
													<img
														src={URL.createObjectURL(companyLogoFile)}
														alt='Company Logo'
													/>
												)}
											</div> */}

										{/* <div className='col-12'>
											<label className='label'>Comapny Logo</label>
											<FormGroup id='login-username'>
												<Input
													type='file'
													autoComplete='companyLogo'
													className='form-control'
													onChange={(e) => companyLogoHandler(e)}
												/>
											</FormGroup>
											{userError.userNameLength && (
												<p className='error'> Please enter userName</p>
											)}
										</div> */}

										<div className='col-12 position-relative'>
											<label className='label'>Password</label>
											<FormGroup
												id='login-password'
												className='position-relative'>
												<>
													<Input
														type={showPassword ? 'text' : 'password'}
														autoComplete='password'
														className='form-control'
														onChange={(e) => passWordHandler(e)}
														placeholder='Password'
													/>
													<button
														type='button'
														className='eye-btn'
														onClick={togglePasswordVisibility}>
														{showPassword ? <FaEye /> : <FaEyeSlash />}
													</button>
												</>
											</FormGroup>
											{userError.passwordLength && (
												<p className='error'>Please enter password</p>
											)}
											{userError.passwordValidation && (
												<p className='error'>
													Password must be 8-14 characters long and
													contain at least one uppercase letter,one
													lowercase letter, one numeric digit, and one
													special character.
												</p>
											)}
										</div>

										<div className='col-12'>
											<label className='label'>Confirm Password</label>
											<FormGroup
												id='login-password'
												className='position-relative'>
												<>
													<Input
														type={
															showConfirmPassword
																? 'text'
																: 'password'
														}
														autoComplete='password'
														className='form-control'
														onChange={(e) => ConfirmPassWordHandler(e)}
														onFocus={handleConfirmPasswordFocus}
														onBlur={handleConfirmPasswordBlur}
														placeholder='Confirm Password'
													/>
													<button
														type='button'
														className='password-toggle-btn eye-btn' // Add appropriate class for styling
														onClick={toggleConfirmPasswordVisibility}>
														{' '}
														{showConfirmPassword ? (
															<FaEye />
														) : (
															<FaEyeSlash />
														)}{' '}
													</button>
												</>
											</FormGroup>

											{userError.passwordMatch && (
												<p className='error'>
													Confirm Password should be same as Password
												</p>
											)}
										</div>

										<div className='col-12'>
											<Button
												// to='/'
												color='warning '
												className='w-100 py-3 btn-continue btn'
												onClick={signUpHandler}>
												Sign up
											</Button>
										</div>

										<div to='/' className='login-btn d-flex'>
											<span>Already have an account?</span>
											<Link to='/'>Login</Link>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
						{/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div> */}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
// Login.propTypes = {
// 	isSignUp: PropTypes.bool,
// };
// Login.defaultProps = {
// 	isSignUp: false,
// };

export default SignUp;
