import React, { useState } from 'react'
import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../components/bootstrap/Card';
import AccountDetail from './AccountDetail';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import EditAccountDetail from './EditAccountDetail';

const YourAccount = () => {
    const [openEditForm, setOpenEditForm] = useState(false)
    return (
        <PageWrapper>
            <Page>
                <div className={`col-lg-12 ${openEditForm ? "col-lg-12" : ""}`}>
                    <Card className='shadow-3d-primary summary-profile'>
                        <CardHeader>
                            <CardLabel iconColor='success'>
                                {!openEditForm ? <CardTitle tag='h4' className='h5 info-head'>
                                    Account Information
                                </CardTitle> :
                                    <CardTitle tag='h4' className='h5 info-head'>
                                        Edit Account Information
                                    </CardTitle>
                                }
                            </CardLabel>
                            {!openEditForm && <Button className="contact-edit edit-btn" onClick={() => setOpenEditForm(true)}><Icon icon="edit" />Edit</Button>}
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-12 mt-0'>
                                    {!openEditForm ? <AccountDetail /> : <EditAccountDetail setOpenEditForm={setOpenEditForm} />}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div >

            </Page>

        </PageWrapper>

    )
}

export default YourAccount