import React, { useState } from 'react';
import Card from '../../../components/bootstrap/Card';
import Icon from '../../../components/icon/Icon';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Web from '../../../assets/img/web-img.png';
import Button from '../../../components/bootstrap/Button';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const GenerateScript = () => {
	const loginData = useSelector((state) => state?.projectsReducer?.loginData);
	const companyId = loginData?.result?.company_id;
	const { id } = useParams();
	const { domainui_id } = useParams();
	console.log('domainui_id', domainui_id);

	// Dev---------------------------------------------------------------------------------------------------------------------------------------------------------

	const scriptTag = `<script id="chatbot_sparx" src="${process.env.REACT_APP_BASE_URL}widget/chat-widget.js?id=${id}&cId=${companyId}"></script>`;

	// UAT-------------------------------------------------------------------------------------------------------------------------------------------------------

	// const scriptTag = `<script id="chatbot_sparx" src="https://chatboting.uatsparxit.xyz/chat-widget/chat-widget.js?id=${id}&cId=${companyId}"></script>`;

	// Live Url--------------------------------------------------------------------------------------------------------------------------------------
	// const scriptTag = `<script id="chatbot_sparx" src=" https://bot.openchatbot.ai/chat-widget.js?id=${id}&cId=${companyId}"></script>`;

	const [showCopyDone, setShowCopyDone] = useState(false);
	const [copyScipt, setCopyScript] = useState(true);

	const copyScriptTag = () => {
		const textarea = document.querySelector('.text-area');
		textarea.select();
		document.execCommand('copy');
		setShowCopyDone(true);
		setCopyScript(false);
		// setTimeout(() => {
		// 	setShowCopyDone(false);
		// 	setCopyScript(true);
		// }, 3000);
	};

	return (
		<PageWrapper title={'Script'}>
			<Page>
				<div className='row'>
					<div className='col-12 script-main-wrap'>
						<Card stretch data-tour='list'>
							<div className='header-wrp'>
								<div className='head-text'>
									<h2>Add script to your website</h2>
									<div className='web-img'>
										<img src={Web} alt='web' />
									</div>
								</div>

								<Link
									to={`/dashboard`}
									color='info'
									icon='Message'
									isLight
									className='trend-btn'>
									Finish 
								</Link>

								{/* <Link to="/trend-modal"
                                    color='info'
                                    icon='Message'
                                    isLight
                                    className="trend-btn"
                                >
                                    Trend Your Modal
                                </Link> */}
								
							</div>
							<p>
								Copy the below code snippet and paste it into your HTML code,
								preferably before the closing body tag .
							</p>

							<div className='script-wrp'>
								<textarea
									name='script'
									rows={7}
									cols={60}
									className='text-area'
									value={scriptTag}
								/>
								<div className='copy_script'>
									<button className='copy-text' onClick={copyScriptTag}>
										<Icon icon='CopyAll' />
									</button>
									{copyScipt && <div className='copy-done'>Copy Script</div>}
									{showCopyDone && <div className='copy-done'>Copy Done</div>}
								</div>
							</div>
							<div className='chatbot-detail'>
								<p>
									<b>Bot ID :</b>
									{domainui_id}
								</p>
								<p>
									<b>Get help : </b>step by step instructions
								</p>
								<p>
									<b>Perfect for : </b> lead collection, customer education,
									customer feedback.
								</p>
							</div>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default GenerateScript;
