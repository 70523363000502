import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/bootstrap/Card"
import Page from "../../layout/Page/Page"
import PageWrapper from "../../layout/PageWrapper/PageWrapper"
import { useState, useEffect } from "react";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Multiselect from "multiselect-react-dropdown";
import Button from "../../components/bootstrap/Button";
import './CreateBot.scss';
import { api } from "../../Api/Index";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function CreateBot() {
    const dispatch = useDispatch();
	const [domainFavIcon, setDomainFavIcon] = useState("https://cdn-icons-png.flaticon.com/512/2235/2235607.png")
	const [isDomainValid, setIsDomainValid] = useState(false)
    const navigate = useNavigate()

	const clearUrl = (url) => {
		let cleanedUrl = url.includes("https")
			? url.slice(8)
			: url.includes("http") && url.slice(7);
		cleanedUrl = cleanedUrl.includes("/") ? cleanedUrl.split("/")[0] : cleanedUrl;
		return cleanedUrl;
	};


	const loginData = useSelector((state) => state?.projectsReducer?.loginData);

    console.log(loginData, 'login dagta? ???');
	const [user, setUser] = useState({
		sitename: '',
		domain: 'https://',
		category: '',
		email: '',
	});

	const [userError, setUserError] = useState({
		sitename: false,
		domain: false,
		validDomain: false,
		category: false,
		email: false,
		emailInvalid: false, // New state for email validation error
	});

	const cleanUp = () => {
		setIsDomainValid(false)
		setDomainFavIcon("https://cdn-icons-png.flaticon.com/512/2235/2235607.png")
		setUser({
			sitename: '',
			domain: 'https://',
			category: '',
			email: '',
		})

		setUserError({
			category: false,
			domain: false,
			email: false,
			emailInvalid: false,
			sitename: false,
			validDomain: false
		})
	}

	useEffect(() => {
		return (() => {
			cleanUp()
		})
	}, [])


	//add-manage-site
	const notify = (message) =>
		toast(message, {
			style: {
				background: 'green',
				color: '#fff',
			},
		});
	// useEffect(() => {
	// 	if (editData) {
	// 		setUser({
	// 			sitename: editData?.website_name,
	// 			domain: editData?.domain,
	// 			category: editData?.category,
	// 			email: editData?.email,
	// 		});
	// 	}
	// }, [editData]);

	const handleSubmit = () => {
		// const domainRegex = /^https?:\/\/(?:\w+\.)+\w+(?::\d+)?\/[\w/-]+$/;
		const expression = /^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/i;
		const domainRegex = new RegExp(expression);

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (user.sitename?.length === 0) {
			setUserError({ ...userError, sitename: true });
			return;
		}

		// Validate email format
		if (!emailRegex.test(user.email)) {
			setUserError({ ...userError, emailInvalid: true });
			return;
		}

		if (user.email?.length === 0) {
			setUserError({ ...userError, email: true });
			return;
		}
		console.log('user--', user);

		if (user.domain?.length === 0) {
			setUserError({ ...userError, domain: true });
			return;
		}

		// If the domain is not in URL format and doesn't match the domain regex, show error
		if (user.domain?.length > 0 && !domainRegex.test(user.domain)) {
			setUserError({ ...userError, validDomain: true });
			return;
		}

		// if (user.domain?.length > 0 && !domainRegex.test(user.domain)) {
		// 	setUserError({ ...userError, validDomain: true });
		// 	return;
		// }

		if (!user.category) {
			// Check if category is empty
			setUserError({ ...userError, category: true });
			return;
		}

        // {
        //     "sitename": "dasdb",
        //     "domain": "https://google.com",
        //     "category": "Sales",
        //     "email": [
        //       "ndbsn@f.com"
        //     ],
        //     "company_id": "889cd469d57e6a9e5683c1dc60099596"
        //   }

        user.company_id = loginData.result.company_id;

        const token = localStorage.getItem('access_token');
        api.post(`/company-dashboard/${loginData.result.company_id}/site`, user, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            toast.success(response.data.message)
            navigate('/manage-site')
        })
	};

	const searchDomain = () => {

		const expression = /^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/i;
		const domainRegex = new RegExp(expression);

		if (user.domain?.length > 0 && !domainRegex.test(user.domain)) {
			setUserError({ ...userError, validDomain: true });
			return;
		}

		const { domain } = user;
		const siteUrl = clearUrl(domain);

		setDomainFavIcon(`http://www.google.com/s2/favicons?domain=${siteUrl}`)
		setIsDomainValid(true)
	}

	const domainCheckHandler = (newValue) => {
		if (isDomainValid) {
			setIsDomainValid(false)
			setDomainFavIcon("https://cdn-icons-png.flaticon.com/512/2235/2235607.png")
		}

		if (!newValue.startsWith('https://')) {
			newValue = ""
			newValue = 'https://' + newValue;
		}

		setUser({ ...user, domain: newValue });

		setUserError({
			...userError,
			validDomain: false,
			domain: false,
		});
	}
	
    return (
        <PageWrapper title={"Open ChatBot"}>
            <Page>
                <Card stretch data-tour='list'>
                    <div className="site-information">
                    <h1 style={{marginBottom:"30px"}}>Create Bot</h1>
                    <form>
						<div className='row g-4 role-modal-body multi-select-wrap  form-group-wrp platform-wrap'>
							<div className='form-group link col-6'>
								<label>Bot Name <span style={{ color: "red" }}>*</span></label>
								<input
									type='text'
									className='form-control'
									placeholder='Bot Name'
									onChange={(e) => {
										setUser({ ...user, sitename: e.target.value });
										setUserError({ ...userError, sitename: false });
									}}
									value={user.sitename}
								/>
								{userError.sitename && (
									<p className='error'>Please enter bot name</p>
								)}
							</div>

							<div className='form-group link col-6'>
								<label>Email ID <span style={{ color: "red" }}>*</span></label>
								<input
									type='text'
									className='form-control'
									placeholder='Email ID'
									onChange={(e) => {
										setUser({ ...user, email: e.target.value?.replace(" ", "")?.split(",") });
										setUserError({
											...userError,
											email: false,
											emailInvalid: false,
										});
									}}
									value={user.email}
								/>
								{userError.email && <p className='error'>Please enter email ID</p>}
								{userError.emailInvalid && (
									<p className='error'>Please enter a valid email ID</p>
								)}
							</div>

							<div className='form-group link col-6'>
								<label>Domain <span style={{ color: "red" }}>*</span></label>
								{/* Domain Icon */}
								<img src={domainFavIcon} alt="icon" style={{ position: 'absolute', left: '10px', top: "67%", left: "2%", transform: 'translateY(-50%)', width: '24px', height: '23px' }} />

								<input
									type='text'
									className='form-control'
									placeholder='Domain'
									onChange={(e) => {
										domainCheckHandler(e.target.value)
									}}
									spellCheck={false}
									value={user.domain}
									style={{ paddingLeft: '30px' }} // Add some padding to prevent text from overlapping the icon
								/>

								{/* Search / Verified Icon */}
								<img src={isDomainValid ? "https://cdn.pixabay.com/photo/2016/03/31/14/37/check-mark-1292787_640.png" : "https://uxwing.com/wp-content/themes/uxwing/download/user-interface/search-icon.png"} onClick={searchDomain} alt="icon" style={{ position: 'absolute', right: '4%', top: "67%", transform: 'translateY(-50%)', width: '20px', height: '20px', cursor: "pointer" }} />

								{userError.domain && (
									<p className='error'>Please enter Domain name</p>
								)}

								{userError.validDomain && (
									<p className='error'>Please enter valid Domain name</p>
								)}

							</div>
							<div className='col-6 single-select'>
								<FormGroup label='Category'>
									<span style={{ color: 'red' }}> *</span>
									<div className='form-wrp '>
										<Multiselect
											options={[
												{
													name: 'Project Manager',
													text: 'Project Manager',
													id: 1,
												},

												{ name: 'Sales', text: 'Sales', id: 2 },

												{
													name: 'Business Development',
													text: 'Business Development',
													id: 3,
												},
											]}
											displayValue='name'
											showArrow={true}
											singleSelect={true}
											// onSelect={(e) =>
											// 	setUser({ ...user, category: e?.[0]?.name })
											// }
											onSelect={(e) => {
												setUser({ ...user, category: e?.[0]?.name });
												setUserError({
													...userError,
													category: !e || e.length === 0,
												});
											}}
											selectedValues={
												user.category ? [{ name: user.category }] : null
											}
										/>

										{userError.category && (
											<p className='error'>Please select category</p>
										)}
									</div>
								</FormGroup>
							</div>

							<div className='role-btn-wrp'>
								{/* <Button icon='Cancel' color='danger' isLight>
									Cancel
								</Button> */}
								<Button icon='Save' color='info' isLight onClick={handleSubmit}>
									Save
								</Button>
							</div>
						</div>
					</form>
                    </div>
                </Card>
            </Page>
        </PageWrapper>
    )
}

export default CreateBot