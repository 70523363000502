import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Icon from '../../../components/icon/Icon';
import MyEditor from './MyEditor/MyEditor';
import { api } from '../../../Api/Index';

const Popup = ({
	allOptionList,
	setAllOptionList,
	setPopup,
	setIsChecked1,
	isChecked1,
	temptt,
	index,
	setFields,
	field,
	selectOption,
	optionSelect,
	setOptionSelect,
	updatedList,
	updateSelectList,
	setUpdateSelectList,
	setCheck2,
	selectedOption,
	setSelectedOption,
	paramId,
	listItem,
	allResponse,
	setAllResponse
}) => {
	let tempFields = [...temptt];

	const [isChecked4, setIsChecked4] = useState(false);
	const [errorMsz, setErrorMsz] = useState(tempFields?.[index]?.error_msg);
	const [optionSelectedValue, setOptionSelectedValue] = useState(1)

	let creatorId = field.tempId;

	const [add, setAdd] = useState(
		[{id:1}]
	);

	function removeDuplicates(arr, prop) {
		const unique = new Set();
		return arr.filter(obj => {
			const key = obj[prop];
			return unique.has(key) ? false : unique.add(key);
		});
	}

	useEffect(()=> {
		console.log(allOptionList, 'allOptionList ???');

	}, [allOptionList])

	useEffect(()=> {
		// get select options
		let optionSelect = field.optionSelect;
		console.log(optionSelect, 'optionSelect >>>');
		let optionArray = []

		if (add.length === 0) {
			// setAdd([{id:1}])
		}
		
		if (optionSelect) {
			optionSelect?.map(item => {
				item?.map(optionItem => {
					if (optionItem.createdBy === creatorId) {
						optionArray.push(optionItem)
					}
				})
			})

			const uniqueArray = removeDuplicates(optionArray, 'id');
			
			if (uniqueArray.length !== 0) {
				setAdd(uniqueArray)
			} else {
				setAdd([{id: 1}])
			}

			setOptionSelectedValue(optionSelectedValue + 1)
		} else {
			// setAdd([{id:1}])
		}
		
	}, [creatorId, field])
	
	useLayoutEffect(()=> {
		const responses = [];

		allOptionList.map(item => {
			item?.map(optionItem => {
				if (optionItem.value) {
					responses.push(optionItem)
				}
			})
		})

		let output = removeDuplicates(responses, 'questionParent')

		console.log(output, allOptionList, 'output ???');

		setAllResponse(output)
	}, [allOptionList])

	const [errMessage, setErrMessage] = useState(tempFields?.[index]?.question_validation);

	const [qus_type, setQus_type] = useState(tempFields?.[index]?.question_type);

	// Validation
	let qusType = ['Phone Number', 'email'];

	useEffect(() => {
		setSelectedOption(
			`${!Number(field?.selected_question_id) ? 0 : field?.selected_question_id}-${
				!Number(field?.select_optionid) ? 0 : field?.select_optionid
			}`,
		);
	}, [field]);

	useEffect(()=> {
		allOptionList?.map((item, itemIndex) => {
			item?.map((option, optionIndex) => {
				if (!option.value) {
					item.splice(optionIndex, 1)
				}
			}) 
		})
	}, [])

	useEffect(()=> {

		field?.optionSelect?.map(item => {
			item?.map(optionItem => {
				if (optionItem.questionParent === selectedOption) {
					setOptionSelectedValue(optionItem.value)
				}
			})
		})

	}, [field, selectedOption])

	const handleCheckboxChange4 = () => {
		setIsChecked4(!isChecked4);
	};

	const handleDelete = (item) => {
		let temp = [...add];
		temp.splice(temp.indexOf(item), 1);
		setAdd(temp);
	};

	const loginData = useSelector((state) => state?.projectsReducer?.loginData);

	const companyId = loginData?.result?.company_id;
	const { id } = useParams();

	const getAllData = (company_id) => {
		const token = localStorage.getItem('access_token');

		api.get(`company-dashboard/${company_id}/site/${paramId}/get-question-list`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(response => {
				response.data.result.map(item => {
					if (item.tempId === field.tempId) {
						field.question_id = item.question_id
						setOptionSelectedValue(123)
					}
				})
			})
	}

	useEffect(()=> {
		getAllData(loginData.result.company_id)
	}, [loginData, paramId, field])

	useEffect(()=> {
		if (add.length === 0) {
			// setAdd([{id:1}])
		}
	},[add])

	//message
	const handleMsg = (data) => {
		let temp = { ...tempFields[index] };
		temp.message = data;
		temp.messageForMail = data.replace(/<[^>]*>/g, '');
		tempFields[index] = { ...tempFields[index], ...temp };
		setFields(tempFields);
	};

	//errorMsz
	const handleError = (e) => {
		setErrorMsz(e.target.value);
		let temp = { ...tempFields[index] };
		temp.error_msg = e.target.value;
		tempFields[index] = { ...tempFields[index], ...temp };
		setFields(tempFields);
	};

	//option
	const handleAdd = (item, index) => {
		let temp = [...add];
		add.forEach((ele, index) => {
			temp[index] = { ...ele, id: index + 1 };
		});
		temp = [...temp, { id: temp.length + 1 }];

		setAdd(temp);
	};

	//add Option
	const handleOption = (e, index) => {
		let temp = [...add];
		temp[index].value = e.target.value;
		temp[index].questionParent = `${field?.question_id}-${temp[index].id}`;
		temp[index].createdBy = field.tempId;

		setAdd(temp);

		console.log('Handle option');
	};

	console.log(add,'add ????');

	//option data
	useEffect(() => {
		const optionsArr = [];
		selectOption?.forEach((option) => {
			if (option.value) {
				optionsArr.push({
					value: option.value,
					label: option.value,
					option_id: `${option.qid}-${option.id}`,
					qid: option.qid,
					notVisible: option.notVisible,
				});
			}
		});
		setOptionSelect(optionsArr);
	}, [selectOption]);

	//done
	const handleDone = () => {
		let tempList = [...allOptionList];
		tempList[index] = add ?? [];

		let selectedOption = '';

		tempList?.forEach((item, index) => {
			item?.forEach((ele, index) => {
				if (`${ele.notVisible}` === `${field.question_id}`) {
					selectedOption = ele.questionParent;
				}
			});
		});

		setAllOptionList(tempList);
		setPopup(false);
		let temp = { ...tempFields[index] };
		if (!temp.required) {
			temp.required = 0;
		}
		if (!temp.message) {
			temp.message = '';
		}
		if (!temp.customer_reply) {
			temp.customer_reply = 0;
		}
		if (!temp.back) {
			temp.back = 0;
		}
		if (!temp.error_msg) {
			temp.error_msg = 0;
		}
		if (!temp.option_html) {
			temp.option_html = '';
		}
		if (!temp.option_id) {
			temp.option_id = 0;
		}
		if (!temp.options) {
			temp.options = '';
		}
		if (!temp.company_id) {
			temp.company_id = companyId;
		}
		if (!temp.site_id) {
			temp.site_id = id;
		}
		if (!temp.type) {
			temp.type = 'Select';
		}
		if (temp.type === 'Ai') {
			temp.bot_redirect = 1;
		} else {
			temp.bot_redirect = 0;
		}

		temp.selected_question_id = selectedOption ? Number(selectedOption.split('-')?.[0]) : 0;
		temp.select_optionid = selectedOption ? Number(selectedOption.split('-')?.[1]) : 0;

		temp.question_validation = errMessage ? errMessage : null;
		temp.options = add;

		temp.optionSelect = optionSelect;
		temp.question_type = qus_type ? qus_type : '';

		let innerHtml = document.getElementById('chatbot-option-values');
		if (temp.type === 'Select') {
			temp.option_html = innerHtml?.outerHTML;
		} else {
			temp.option_html = '';
			if (selectedOption) {
				temp.selected_question_id = Number(selectedOption?.split('-')?.[0])
					? Number(selectedOption.split('-')?.[0])
					: 0;
				temp.select_optionid = Number(selectedOption?.split('-')?.[1])
					? Number(selectedOption.split('-')?.[1])
					: 0;
			}
		}

		// Add questionType key
		// Retain the existing questionType if it exists
		//  if (temp.question_type) {
		// 	temp.question_type = temp.question_type;
		// }

		tempFields[index] = { ...tempFields[index], ...temp };
		let tempOptions = [...tempFields];
		tempOptions?.forEach((item, index) => {
			tempOptions[index] = { ...tempOptions[index], optionSelect: tempList };
		});
		setFields(tempOptions);
	};
	const errorMessageHandler = (e) => {
		setErrMessage(e.target.value);
	};

	const handleMandatoryChange = (e) => {
		let value = e.target.value;

		if (value === 'Yes') {
			field.required = 1;
		} else {
			field.required = 0;
		}
	}

	const handleChangeQusType = (e) => {
		const selectedQuestionType = e.target.value;

		setQus_type(e.target.value);

		setFields((prevData) =>
			prevData.map((item, i) => {
				if (i === index) {
					return {
						...item,
						question_type: selectedQuestionType,
					};
				}
				return item;
			}),
		);
	};

	const handleChange = (e) => {
		const tempList = [...allOptionList];
		tempList?.forEach((item, index) => {
			item?.forEach((option, subIndex) => {
				if (option.questionParent === e.target.value) {

					setSelectedOption(option.questionParent);
					tempList[index][subIndex] = {
						...tempList[index][subIndex],
						notVisible: field?.question_id,
						notVisibleFor: field.tempId
					};
				}
				if (
					option.questionParent !== e.target.value &&
					option.notVisible === field?.question_id
				) {
					tempList[index][subIndex] = {
						...tempList[index][subIndex],
						notVisible: undefined,
					};
				}
			});
		});

		setAllOptionList(tempList);
		setOptionSelect(tempList);

		let tempAdd = [...add];
		tempAdd?.forEach((option, subIndex) => {
			if (option.questionParent === e.target.value) {
				tempAdd[subIndex] = {
					...tempAdd[subIndex],
					notVisible: field?.question_id,
				};
				
				setSelectedOption(option.questionParent);
			}
			if (
				option.questionParent !== e.target.value &&
				option.notVisible === field?.question_id
			) {
				tempAdd[subIndex] = {
					...tempAdd[subIndex],
					notVisible: undefined,
				};
			}
		});

		setAdd(tempAdd);
	};

	return (
		<div className='popup-wrp-editor'>
			<div className='head-wrp'>
				{tempFields?.type === 'Phone' ? (
					<h3>Phone Number</h3>
				) : tempFields?.type === 'Email' ? (
					<h3>Email</h3>
				) : tempFields?.type === 'Select' ? (
					<h3>Choice</h3>
				) : tempFields?.type === 'Message' ? (
					<h3>Text</h3>
				) : (
					<h3>Message</h3>
				)}

				<button className='cross-btn' onClick={handleDone}>
					Done
				</button>
			</div>
			<div className='modal-cke'>
				<MyEditor value={tempFields?.[index]?.message} handleMsg={handleMsg} />
			</div>

			{field?.type === 'Email' && (
				<div className='main-toggle-wrp rejected-email'>
					<div className='additional-data toggle-main-wrrap'>
						<div className='msz-wrp'>
							<h3>Reject email providers like Gmail, Yahoo and more</h3>
							<label class='switch'>
								<input
									type='checkbox'
									checked={isChecked4}
									onChange={handleCheckboxChange4}
								/>
								<span class='slider round'></span>
							</label>
						</div>
					</div>
				</div>
			)}

			<div className='extra-data-type'>
				{field?.type === 'Email' && (
					<div className='input-data'>
						<label>Error message when visitor enters invalid answer</label>
						<input
							type='text'
							placeholder='Please enter a valid email address'
							onChange={handleError}
							value={errorMsz}
						/>
					</div>
				)}

				{(field?.type === 'Select' || field?.type === 'Ai') && (
					<div className='option-data'>
						<label>Options</label>
						{add?.map((item, index) => {
							return (
								<div className='add-wrp' key={index}>
									<input
										type='text'
										value={add?.[index]?.value ? add?.[index]?.value : ''}
										placeholder='Enter your Options'
										onChange={(e) => handleOption(e, index)}
									/>
									<button
										className='add-btn'
										onClick={() => handleAdd(item, index)}>
										+
									</button>
									{index > 0 && (
										<button
											className='add-btn delete'
											onClick={() => handleDelete(item)}>
											<Icon icon='Delete' />
										</button>
									)}
								</div>
							);
						})}
					</div>
				)}

				<div className='chatbot-option-values' id='chatbot-option-values'>
					<h4 dangerouslySetInnerHTML={{ __html: tempFields?.[index]?.message }} />
					{add?.map((item, index) => {
						return item?.value ? (
							<button
								key={index}
								className='ques_block option-block'
								id={field?.id + '-' + item?.id}>
								{item?.value}
							</button>
						) : null;
					})}
				</div>

				{tempFields?.[index]?.type !== 'Select' && (
					<>
						<div className='select-wrapper' style={{ marginBottom: '10px' }}>
							<label>Question type:</label>
							<select onChange={handleChangeQusType} className='custom-select'>
								<option>Please Select</option>
								{qusType &&
									qusType.map((option, idx) => (
										<option
											key={idx}
											selected={option === field?.question_type}>
											{option}
										</option>
									))}
							</select>
						</div>

						<div className='option-data error-validation'>
							<label htmlFor='messageError'>Please enter your error message:</label>
							<div className='add-wrp'>
								<input
									type='text'
									id='messageError'
									value={errMessage}
									placeholder='Enter your message'
									onChange={errorMessageHandler}
								/>
							</div>
						</div>
					</>
				)}

				<div className='select-wrapper'>
					<label>This is a response for:</label>
					<select onChange={handleChange} className='custom-select'>
						<option>Please Select</option>

						{/* {allOptionList?.map((item) => {
							console.log(item,'item ???');
							return item?.map((option) => {
								return (
									<option
										hidden={option?.notVisible || option.createdBy === field.tempId}
										selected={option.notVisible === field?.question_id}
										key={option.id}
										value={option.questionParent}>
										{option.value}
									</option>
								)
							});
						})} */}

						{allResponse.map(((option) => {
							return (
								<option
										hidden={option?.notVisible || option.createdBy === field.tempId}
										selected={option.notVisible === field?.question_id}
										key={option.id}
										value={option.questionParent}>
										{option.value}
									</option>
							)
						}))}
					</select>
				</div>

				<div className='select-wrapper' style={{marginTop:"10px"}}>
					<label>Is Mandatory ?</label>
					<select onChange={handleMandatoryChange} defaultValue={field.required === 1 ? "Yes" : "No"} className='custom-select'>
						<option>Yes</option>
						<option>No</option>
					</select>
				</div>
			</div>
		</div>
	);
};

export default Popup;
