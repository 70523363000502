import React, { useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import uploadImage from '../../../assets/img/uploadIcon.png'
import { useDispatch } from 'react-redux';
import { UploadAvatarImageAction, getAvatarListAction } from '../../../Store/Action';

const AvtarSelect = ({ setAvatar, avatarList, avatar, setImgUpload, preview, setPreview, setAvatarRight,company_id ,setUploadImg}) => {

    const dispatch=useDispatch()

    var settings = {
        dots: false,
        infinite: false,
        arrow: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },

        ],
    };
    const handlePreview = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imgUrl = URL.createObjectURL(file)
            setPreview(imgUrl)
            setAvatar()
            setImgUpload(file)
            setAvatarRight(imgUrl)
            
            
        }

        setUploadImg(file)
        
        let payload={
            image:file
        }
        
        console.log("payload11112222", payload)


        // UploadAvatarImageAction(dispatch,{ image:file}, company_id);

    }
    const handleAvatar = (e) => {
        console.log("avatarE", e)
        setAvatar(e)
        setImgUpload()
        setPreview()
    }
    return (
        <div className='avtar-wrap theme-head'>
            <h3>Avatar Logo
            </h3>
            <div className='icon-field-wrp form-group-wrp'>
                <div className='project-icon-wrap'>
                    <div className='slider-wrapper'>
                        <Slider {...settings}>
                            <div
                                className={`upload-img-wrap icon-wrp gray-bg upload-project-icon ${preview ? 'active' : ''}`}
                            >

                                <div className='upload-img'>
                                    <img src={preview || uploadImage} alt="preview" />
                                    <input
                                        type='file'
                                        multiple
                                        // accept='.bin'
                                        accept='.jpg,.png,.jpeg'
                                        onChange={handlePreview}
                                    />
                                </div>
                            </div>

                            {avatarList?.result?.length > 0 &&
                                avatarList?.result?.map((item, index) => {
                                    return (
                                        <div
                                            className={`avtar-img-wrpp icon-wrp ${avatar == item?.id ? 'active' : ''}`}
                                            key={item?.id} onClick={() => handleAvatar(item?.id)}
                                        >
                                            <img src={`data:image/png;base64,${item?.avatar_img}`} alt="preview" />
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default AvtarSelect