import { api } from '../Api/Index';
import { actionType } from './ActionType';


	// =====================Auto Logout===========================================================


function failedApiHandler(error, apiCaller) {
	let { status, statusText } = error.response;

	let refresh_token = localStorage.getItem('refresh_token');

	if (!refresh_token) {
		localStorage.clear();
		window.location.href = '/';
		navigate('/');
	}

	if (status === 401 || statusText === 'Unauthorized') {
		// Request for a refresh token
		api.get('company-dashboard/user-refresh-token/', {
			headers: {
				Authorization: `Bearer ${refresh_token}`,
			},
		})
			.then((response) => {
				localStorage.setItem('access_token', response.data.access_token);
				localStorage.setItem('refresh_token', response.data.refresh_token);
				apiCaller();
			})
			.catch((error) => {
				window.location.href = '/';
				navigate('/');
				localStorage.clear();
			});
	}
}

	// =====================Auto Logout===========================================================


//addQuestion
export const addQuestionAction = (dispatch, payload, company_id, id) => {
	const token = localStorage.getItem('access_token');
	dispatch({ type: actionType.LOADERSTART });
	api.post(`company-dashboard/${company_id}/site/${id}/upload-rulebased-data`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.LOADERSTOP });
			if (res?.data?.status == true) {
				getQuestion(dispatch, company_id, id);
				// window.location.reload();
			}
		})
		.catch((err) => {
			dispatch({ type: actionType.LOADERSTOP });
			failedApiHandler(err, () => addQuestionAction(dispatch, payload, company_id, id));
		});
};

export const editQuestionAction = (dispatch, payload, company_id, id) => {
	console.log('data', payload);
	const token = localStorage.getItem('access_token');
	dispatch({ type: actionType.LOADERSTART });
	api.put(`company-dashboard/${company_id}/site/${id}/upload-rulebased-data`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.LOADERSTOP });
 
			if (res?.data?.status == true) {
				getQuestion(dispatch, company_id, id);
				// window.location.reload();
			}
		})
		.catch((err) => {
			dispatch({ type: actionType.LOADERSTOP });
			failedApiHandler(err, () => editQuestionAction(dispatch, payload, company_id, id));
		});
};
//getQuestion

export const getQuestion = (dispatch, company_id, id) => {
	const token = localStorage.getItem('access_token');
	api.get(`company-dashboard/${company_id}/site/${id}/get-question-list`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			if (res?.data?.status === 'false') {
				alert('Session expired');
				window.location = '/';
			} else {
				dispatch({ type: actionType.GET_QUESTION, payload: res?.data });
			}
		})
		.catch((e) => {
			failedApiHandler(e, () => getQuestion(dispatch, company_id, id));
			console.log(e);
		});
};
