import React from 'react'
import HelloGif from '../../../assets/img/waving_hand.gif'
import Icon from '../../../components/icon/Icon'

const Welcome = ({ customAvatarRight, welcomeMessage, color, position, setWelcomeMessage, setHandleSample, setChat, customWelcomeImageId, customWelcomeText ,avatarRight}) => {

    const getClass = () => {
        if (position === "BL") {
            return "bl"
        }
        if (position === "VRC") {
            return "vrc"
        }
        if (position === "BR") {
            return "br"
        }
        return "br"
    }
    const handleWelcomeSample = () => {
        setHandleSample(true)
        setChat(prev => !prev)
    }
    console.log("customAvatarRight", customAvatarRight)

    return (
        <>
            <div className={`welcome-block welcome-initail-block ${getClass()}`}>
                <div className='close-chat' onClick={() => setWelcomeMessage(false)}><Icon icon="Close" /></div>

                <div className='welcome-text'>
                    <div className='img-wrp' style={{ backgroundColor: color, borderColor: color }}>
                        {/* <img src={customAvatarRight} alt="preview" /> */}
                        <img src={avatarRight} alt="preview" />
                    </div>
                    <p>{customWelcomeText}</p>
                    <div className='sample-text' style={{ borderColor: color, color: color }} onClick={handleWelcomeSample}>
                        Sample Option
                    </div>
                </div>
            </div>
        </>
    )
}

export default Welcome



