import { FALSE } from "sass";
import { actionType } from "./ActionType";

const initialState = {
	loader: false
}

const projectsReducer = (state = initialState, action) => {
	switch (action?.type) {
		case actionType.LOGIN:
			return {
				...state,
				loginData: action.payload,
				loader: false
			}
		case actionType.LOADERSTART:
			return {
				...state,
				loader: true
			}
		case actionType.LOADERSTOP:
			return {
				...state,
				loader: false
			}
		case actionType.SITE_LIST:
			return {
				...state,
				loader: false,
				siteList: action.payload
			}
		case actionType.ADD_SITE:
			return {
				...state,
				siteData: action.payload,
				loader: false
			}

		case actionType.EDIT_SITE:
			return {
				...state,
				editSiteData: action.payload,
				loader: false

			}
		case actionType.COMPANY_DETAIL:
			return {
				...state,
				companyDetail: action.payload,
				loader: false

			}
		case actionType.CATEGORY_DATA:
			return {
				...state,
				categoryData: action.payload,
				loader: false

			}
		case actionType.AVATAR_LIST:
			return {
				...state,
				avatarList: action.payload,
				loader: false

			}
		case actionType.GET_DESIGN:
			return {
				...state,
				getDesign: action.payload,
				loader: false

			}
		case actionType.GET_QUESTION:
			return {
				...state,
				getQuesData: action.payload,
				loader: false

			}
		case actionType.GET_QUESTION_DELETE:
			return {
				...state,
				getQuesData: [],
				loader: false

			}
			case actionType.OPTION_LIST:
				return {
					...state,
					updatedOptionList:action.payload,
					loader:false
				}
				// case actionType.GET_DOMAIN_ID:
				// return {
				// 	...state,
				// 	getDomainId:action.payload,
				// 	loader:false
				// }
		default:
			return state;

	}
}
export default projectsReducer;