import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Card from '../../components/bootstrap/Card'
import Page from '../../layout/Page/Page'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import CommonServicesList from '../common/BookingComponents/CommonServicesList'
import SERVICES from '../../common/data/serviceDummyData'
import { priceFormat } from '../../helpers/helpers'
import './style.scss';
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
    const [search, setSearch] = useState("")

    const dataServices = [
        {
            id: 1,
            name: <input type='checkbox' />,
            ofAppointments: 'F',
            sumOfPayments: 'Nixon',
            ofHoursInAppointment: 'nixon@sparxitsolutions.com',
            ofLoad: 'ICON',
            color: SERVICES.TENNIS.color,
            icon: SERVICES.TENNIS.icon,
            pageStayTime: "2 Minutes"
        },
        {
            id: 2,
            name: <input type='checkbox' />,
            ofAppointments: 'F',
            sumOfPayments: 'Robb',
            ofHoursInAppointment: 'robb@sparxitsolutions.com',
            ofLoad: 'ICON',
            color: SERVICES.SURFING.color,
            icon: SERVICES.SURFING.icon,
            pageStayTime: "2 Minutes"
        },
        {
            id: 3,
            name: <input type='checkbox' />,
            ofAppointments: 'F',
            sumOfPayments: 'Alex',
            ofHoursInAppointment: 'alex@sparxitsolutions.com',
            ofLoad: 'ICON',
            color: SERVICES.KITE_SURFING.color,
            icon: SERVICES.KITE_SURFING.icon,
            pageStayTime: "2 Minutes"
        },
        {
            id: 4,
            name: <input type='checkbox' />,
            ofAppointments: 'F',
            sumOfPayments: 'Max',
            ofHoursInAppointment: 'max@sparxitsolutions.com',
            ofLoad: 'ICON',
            color: SERVICES.KAYAKING.color,
            icon: SERVICES.KAYAKING.icon,
            pageStayTime: "2 Minutes"
        },
        {
            id: 5,
            name: <input type='checkbox' />,
            ofAppointments: 'F',
            sumOfPayments: 'Phil',
            ofHoursInAppointment: 'phil@sparxitsolutions.com',
            ofLoad: 'ICON',
            color: SERVICES.CRICKET.color,
            icon: SERVICES.CRICKET.icon,
            pageStayTime: "2 Minutes"
        },
        {
            id: 6,
            name: <input type='checkbox' />,
            ofAppointments: 'F',
            sumOfPayments: 'Sally',
            ofHoursInAppointment: 'sally@sparxitsolutions.com',
            ofLoad: 'ICON',
            color: SERVICES.ICE_SKATING.color,
            icon: SERVICES.ICE_SKATING.icon,
            pageStayTime: "2 Minutes"
        },
    ];

    const header = [
        {
            name: <input type='checkbox' style={{ marginLeft: "13px" }} />,
            accessors: "name"
        },
        {
            name: 'Flag',
            accessors: "ofAppointments"
        },
        {
            name: "Name",
            accessors: "sumOfPayments"
        },
        {
            name: "Email",
            accessors: "ofHoursInAppointment"
        },
        {
            name: "Mobile Number",
            accessors: "ofLoad"
        },
        {
            name: "Page Stay Time",
            accessors: "ofLoad"
        },
        {
            name: "Action",
            accessors: "ofLoad",
        },
    ]

    function show(item) {
        const navigate = useNavigate();

        const actionHandler = () => {
            navigate("/dashboard/transcript/1")
        }

        return (
            <tr key={item.id}>
                <td>
                    <div className='d-flex'>

                        <div className='flex-grow-1 ms-3 d-flex align-items-center'>
                            {item.name}
                        </div>
                    </div>
                </td>
                <td>{item.ofAppointments}</td>
                <td>{priceFormat(item.sumOfPayments)}</td>
                <td>{item.ofHoursInAppointment}</td>
                <td>
                    <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0 me-3'>{`${item.ofLoad}%`}</div>
                    </div>
                </td>
                <td>
                    <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0 me-3'>{`${item.pageStayTime}`}</div>
                    </div>
                </td>
                <td>
                    <div className='d-flex align-items-center' onClick={actionHandler}>
                        <div className='flex-shrink-0 me-3'>{`${item.ofLoad}`}</div>
                    </div>
                </td>
            </tr>
        )
    }

    return (
        <PageWrapper title={'Role and Permission'}>
            <Page>
                <Card stretch data-tour='list'>
                    {/* Record Info */}
                    {/* <div className='list-records'>
                        <div className='list-record-data'>
                            <h1>Total Number of Chats</h1>
                            <p>250</p>
                        </div>

                        <div className='list-record-data'>
                            <h1>Total Page Stay Time</h1>
                            <p>225 Minutes</p>
                        </div>

                        <div className='list-record-data'>
                            <h1>Total Travel Pages</h1>
                            <p>150</p>
                        </div>
                    </div> */}

                    {/* Chat Info */}

                    <div className='chat-info'>
                        <h1>Chat Info</h1>

                        <div className='chat-features'>
                            {/* Inbox - Search Box */}
                            <div className='chat-features-box'>
                                <input type='text' value={search} onChange={e => setSearch(e.target.value)} placeholder='Search by visitors name or email'/>
                            </div>

                            {/* Buttons n Features */}
                            <div className='chat-features-box'>
                                <div className='select-box'>
                                    <select>
                                        <option>- Select Range -</option>
                                    </select>
                                </div>

                                <button>Apply</button>
                                <button>Export CSV</button>
                            </div>
                        </div>

                    </div>
                    <CommonServicesList data={dataServices} headers={header} show={show} />
                </Card>

            </Page>
        </PageWrapper>
    )
}

export default Dashboard