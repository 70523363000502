import React, { useState } from 'react'
import { Sketch } from '@uiw/react-color';
import { Alpha, Hue, ShadeSlider, Saturation, Interactive, hsvaToHslaString } from '@uiw/react-color';
import { EditableInput, EditableInputRGBA, EditableInputHSLA } from '@uiw/react-color';

const Theme = ({ setColor, color }) => {
    const handleColor = (e) => {
        setColor(e)
    }

    const [hex, setHex] = useState("#fff");
    const [showHexSelector, setShowHexSelector] = useState(false)


    const [colorBox, setColorBox] = useState([
        {
            color: "#DE3163",
            isActive: true
        },
        {
            color: "#9dcf00",
            isActive: true
        },
        {
            color: "#000080",
            isActive: true
        },
        {
            color: "#6495ED",
            isActive: true
        },
        {
            color: "#8c52ff",
            isActive: true
        },
        {
            color: "#FFBF00",
            isActive: true
        },
        {
            color: "#FF7F50",
            isActive: true
        },
        {
            color: "#40E0D0",
            isActive: true
        },
    ])

    return (
        <div className='theme-wrap theme-head'>
            <h3>Theme
            </h3>
            <div className='theme-box-wrap'>


                {colorBox.map((item, index) => (
                    <>
                        <div className={`color-box ${color === item.color ? "active-color" : ""}`} style={{ backgroundColor: `${item.color}` }} onClick={(e) => {
                            if (item.isHexSelector) {
                                setShowHexSelector(true)
                            } else {
                                setShowHexSelector(false)
                                setHex("#fff")
                                handleColor(item.color)
                            }
                        }}>
                            {color === item.color && !showHexSelector ? <img style={{ width: "22px", marginTop: "8px", marginLeft: "5px" }} src='https://propta.com/wp-content/uploads/2021/12/vector-black-and-white-tick-svg-png-icon-9.png' /> : <h1></h1>}

                        </div>

                        {index === colorBox.length - 1 && (
                            <div onClick={() => setShowHexSelector(!showHexSelector)}>
                                {hex !== "#fff" && (
                                <img style={{ width: "22px", marginTop: "10px", marginLeft: "7px", position: "absolute" }} src='https://propta.com/wp-content/uploads/2021/12/vector-black-and-white-tick-svg-png-icon-9.png' />
                                )}
                                {
                                    !showHexSelector && hex === "#fff" ? (
                                        <img src='https://imagecompresser.com/color-picker.jpg' className={`color-box ${showHexSelector ? "active-color" : ""}`} />
                                    ) : (
                                        <div className={`color-box`} style={{ backgroundColor: `${hex}` }}></div>
                                    )
                                }
                            </div>
                        )}
                    </>
                ))}

                {showHexSelector && (
                    <div className='picker-color-wrap'>
                    <>
                    <p className='close-button' onClick={()=> setShowHexSelector(false)}>x</p>
                    <Sketch
                        // style={{ marginLeft: 20,position:"absolute", zIndex:"1", bottom:"-60px" }}
                        color={hex}
                        onChange={(color) => {
                            handleColor(color.hex)
                            setHex(color.hex);
                        }}
                    />
                    </>
                    </div>
                )}
            </div >

        </div >
    )
}

export default Theme;
