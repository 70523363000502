import React, { useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ManageText = ({ setCustomWelcomeText, customWelcomeText }) => {

    return (
        <div className='avtar-wrap theme-head custom-welcome'>
            <h2>Customize Text
            </h2>
            <div className='Message-select-wrp'>
                <div className='single-select '>
                    <textarea
                        name="script"
                        className="text-area"
                        rows={1}
                        value={customWelcomeText}
                        onChange={(e) => { setCustomWelcomeText(e.target.value) }}
                        maxLength={25}
                    />
                </div>
            </div>

        </div >
    )
}

export default ManageText