import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { demoPages, layoutMenu } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { actionType } from '../Store/ActionType';
import Loader from '../components/Loader';
import idleTimeDetector from '../contexts/idleTimeDetector';
import { useHistory } from 'react-router-dom';
const App = () => {
	const loader = useSelector((state) => state.projectsReducer.loader);
	const loginData = useSelector((state) => state?.projectsReducer?.loginData);
	const dispatch = useDispatch();
	// const history = useHistory();
	getOS();

	// =====================Auto Logout===========================================================

	const idleTimeDetectorRef = useRef();

	const handleIdle = () => {
	    // setIsIdle(true);
	    // idleTimeDetectorRef.current.stopTracking();
	    console.log("It is idle")
	 };

	idleTimeDetectorRef.current = idleTimeDetector(30);
	idleTimeDetectorRef.current.startTracking(handleIdle);

	setInterval(()=> {
	    // Track the seconds
	    let seconds = Number(localStorage.getItem("idleStatus"))

	    const {pathname} = window.location;
	    // /forget-password, /sign-up, /

	    if (pathname === "/forget-password" || pathname === "/sign-up" || pathname === "/") {

	    } else {
	 // for 2 mins
	        if (seconds >= 1440000) {
	            console.log("Clear the local")
	            localStorage.clear()
	            window.location.href = '/';
	        }
	    }
	},1000)

	// =====================Auto Logout===========================================================

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = ['/', 'sign-up', demoPages.signUp.path, layoutMenu.blank.path];
	//set token and id in localstorage

	useEffect(() => {
		const token = localStorage.getItem('access_token');
		const companyId = localStorage.getItem('company_id');
		dispatch({
			type: actionType.LOGIN,
			payload: { access_token: token, result: { company_id: companyId } },
		});
	}, []);
	return (
		<ThemeProvider theme={theme}>
			{loader ? <Loader /> : null}
			<ToastProvider components={{ ToastContainer, Toast }}>
				<Toaster />
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
							zIndex: fullScreenStatus && 1,
							overflow: fullScreenStatus && 'scroll',
						}}>
						<Routes>
							{withOutAsidePages.map((path) => (
								<Route key={path} path={path} />
							))}
							<Route path='*' element={<Aside />} />
						</Routes>
						<Wrapper />
					</div>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
