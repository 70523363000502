import { useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import update from "immutability-helper";

// import { useSortable } from "@dnd-kit/sortable";

import Icon from '../../../components/icon/Icon';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import { useCallback, useEffect, useState } from 'react';
import Popup from '../Editor/Popup';
import {
	addQuestionAction,
	editQuestionAction,
	getQuestion,
} from '../../../Store/DragAndDropAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { actionType } from '../../../Store/ActionType';
import Swal from 'sweetalert2';
import { api } from '../../../Api/Index';
import toast from 'react-hot-toast';

import { useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';

export const ItemTypes = {
	CARD: 'card',
}


let updatedList = [];

export function Field(props) {
	const { field, overlay, ...rest } = props;
	const { type } = field;
	// const Component = getRenderer(type);
	let className = 'canvas-field';
	if (overlay) {
		className += ' overlay';
	}

	return (
		<div className={className}>
			<Component {...rest} />
		</div>
	);
}

function SortableField(props) {
	const {
		allOptionList,
		setAllOptionList,
		id,
		index,
		field,
		temptt,
		setFields,
		questionData,
		getJoinData,
		selectOption,
		dragFields,
		optionSelect,
		setOptionSelect,
		updateSelectList,
		setUpdateSelectList,
		setCheck2,
		selectedOption,
		setSelectedOption,
		updateData,
		deleteSelectCheckHandler,
		itemId,
		moveCard
	} = props;

	const [popup, setPopup] = useState(false);

	const dispatch = useDispatch()

	const [deletePopup, setDeletePopup] = useState(false);

	const loginData = useSelector((state) => state?.projectsReducer?.loginData);

	const [allResponse, setAllResponse] = useState([])

	const ref = useRef(null);

	const [{ handlerId }, drop] = useDrop({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves

			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position

			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = clientOffset.y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%
			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}
			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}
			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
		drop(item, monitor) {
			console.log('Drop completed', item, monitor);
		}
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			return { id, index };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(drop(ref));

	const { id: paramId } = useParams();

	function deleteItemFromResponse() {
		let selectedDeleteId = field.tempId;

		let filteredRecord = allResponse.filter(item => {
			return item.createdBy !== selectedDeleteId
		})

		// let allOptionListfilter = allOptionList.filter(item => {
		// 	let filterRecord = item.filter(optionItem => {
		// 		return optionItem.createdBy !== selectedDeleteId
		// 	})
		// 	console.log(filterRecord, 'filterRecord');
		// 	return filterRecord
		// })

		let temp = []
		allOptionList?.forEach((item) => {
			let temp2 = [];
			item?.forEach((ele) => {
				if (ele.createdBy !== selectedDeleteId) {
					temp2.push(ele);
				}
			})
			temp.push(temp2)
		})

		console.log('settt 3', temp);

		setAllOptionList(temp)
	}

	function deleteSelectOption(notVisibleId) {
		let newArray = getJoinData;

		newArray?.map(item => {
			let options = item?.optionSelect;
			options?.map(optionItem => {
				optionItem?.map(option => {
					if (option.notVisibleFor === notVisibleId) {
						delete option.notVisible;
						delete option.notVisibleFor;
					}
				})
			})
		})

		setFields(newArray)
	}

	const deleteHandler = (company_id, id, index, fieldType) => {
		const token = localStorage.getItem('access_token');

		updateData(drafts => {
			drafts.fields = []
		})

		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!"
		}).then((result) => {
			if (result.isConfirmed) {
				api.post(`company-dashboard/${company_id}/site/${paramId}/delete-question/${itemId}/`, null, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
					.then(response => {
						deleteItemFromResponse()
						deleteSelectOption(field.tempId)


						// console.log(response,'response');
						if (Boolean(response.data.status) === false) {
							// console.log('Inside the false response');
							// Data is not stored and needs to be deleted locally

							let sortedStates = temptt.filter(item => {
								return item.id !== itemId
							})

							setFields(sortedStates)

							deleteSelectCheckHandler(itemId, getJoinData, true, index, true)

							let filter = getJoinData.filter(item => {
								console.log(item, 'option item');

								// return item.question_id !== itemId
								if (item.optionSelect) {
									item?.optionSelect.map(optionItem => {
										optionItem?.map((optionSelect, index) => {
											if (optionSelect.createdBy === field.tempId) {
												optionItem.splice(index, 1)
											}
										})
									})

									return item.question_id !== itemId
								} else {
									return item.question_id !== itemId
								}
							})

							setFields(filter)

							toast.success("Question Deleted Successfully")
						} else {
							toast.success(response.data.message)

							// deleteItemFromResponse()

							dispatch({ type: actionType.GET_QUESTION_DELETE });

							getAllData(company_id, itemId, index)
						}
					})
			}

		});
	}

	const getAllData = (company_id, id, deleteItemIndex) => {
		const token = localStorage.getItem('access_token');

		api.get(`company-dashboard/${company_id}/site/${paramId}/get-question-list`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then(response => {
				setFields(response.data.result)
				deleteSelectCheckHandler(id, response.data.result, false, deleteItemIndex, true)

			})
	}

	const [isChecked1, setIsChecked1] = useState(false);

	console.log(allOptionList, 'allOptionList 22222');

	return (
		<div>
			<div className='main-csk-wrap' ref={ref} data-handler-id={handlerId}>
				<div className='csk-data'>
					{field?.message ? (
						<div
							className='message'
							dangerouslySetInnerHTML={{ __html: field?.message }}
						/>
					) : (
						<h6>{field?.type}</h6>
					)}
				</div>
				<div className='csk-icon-btn'>
					<button className='btn edit' onClick={() => setPopup(true)}>
						<Icon icon='edit' />
					</button>

					<button className='btn edit' onClick={() => deleteHandler(loginData?.result?.company_id, id, index, field.type)}>
						<Icon icon='delete' />
					</button>

				</div>
			</div>

			{(allOptionList && popup) && (
				<Popup
					field={field}
					setFields={setFields}
					index={index}
					setPopup={setPopup}
					setIsChecked1={setIsChecked1}
					isChecked1={isChecked1}
					temptt={temptt}
					selectOption={selectOption}
					optionSelect={optionSelect}
					setOptionSelect={setOptionSelect}
					updatedList={updatedList}
					updateSelectList={updateSelectList}
					setUpdateSelectList={setUpdateSelectList}
					setCheck2={setCheck2}
					allOptionList={allOptionList}
					setAllOptionList={setAllOptionList}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					paramId={paramId}
					allResponse={allResponse}
					setAllResponse={setAllResponse}
				/>
			)}

			<Modal isOpen={deletePopup} setIsOpen={setDeletePopup} className='screenshot-modal'>
				<ModalHeader>
					<ModalTitle onClick={() => setDeletePopup(false)}>
						<Icon icon='Close' size='2x' />
					</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure to delete this?</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						onClick={() => setDeletePopup(false)}
						className='btn-modal'>
						No
					</Button>
					<Button
						color='info'
						onClick={() => setDeletePopup(false)}
						className='btn-modal'>
						Yes
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
}

export default function Canvas(props) {
	var dragFields = props?.fields;
	const loginData = useSelector((state) => state?.projectsReducer?.loginData);

	const getQuestionData = useSelector((state) => state?.projectsReducer?.getQuesData);

	// const getDesign = useSelector((state) => state?.projectsReducer?.getDesign);
	// console.log("getDesign",getDesign)

	const { fields, updateData } = props;

	const [temptt, setTemptt] = useState();
	const [getJoinData, setJoinData] = useState(null);

	const getDesign = useSelector((state) => state?.projectsReducer?.getDesign)

	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate()

	const [selectOption, setSelectOption] = useState();
	const [questionData, setQuestionData] = useState();
	const [optionSelect, setOptionSelect] = useState();
	const [allOptionList, setAllOptionList] = useState([]);
	console.log("allOptionList 333", allOptionList);
	const [selectedOption, setSelectedOption] = useState(null);

	const [updateSelectList, setUpdateSelectList] = useState({});

	const [check, setCheck] = useState(true);
	const [check2, setCheck2] = useState(true);

	// useEffect(() => {
	// 	if (id) {
	// 		getDesignData(dispatch, loginData?.result?.company_id, id);
	// 	}
	// }, [loginData]);

	useEffect(() => {
		setQuestionData(getQuestionData);
		let tempCondition = false;

		getQuestionData?.result?.[0]?.optionSelect?.forEach((item, index) => {
			if (Array.isArray(item)) {
				tempCondition = true;
			}
		});

		if (getQuestionData?.result?.[0]?.optionSelect && tempCondition) {
			console.log("settttt 2", getQuestionData?.result?.[0]?.optionSelect);
			setAllOptionList(getQuestionData?.result?.[0]?.optionSelect);
		} else {
			let tempList = [...allOptionList];
			getQuestionData?.result?.forEach((item, index) => {
				tempList[index] = item.options;
			});

			console.log("settttt 1", tempList);
			console.log("allOptionList 32323", allOptionList, getQuestionData);

			// setAllOptionList(tempList);
		}
	}, [getQuestionData]);

	const optionBlock = (data) => {
		console.log(data, 'dragable data');
		let tempData = [];
		if (data?.length > 0) {
			tempData = [...tempData, ...data];
		}
		let mainTemp = [];
		let mainTemp2 = [];
		let temp = [];
		let tempJoin = [];
		let tempField = [];

		tempData?.forEach((ele) => {
			tempJoin.push(ele.tempId);
		});

		fields?.forEach((ele) => {
			tempField.push(ele.id);
		});

		fields.forEach((ele) => {
			if (!tempJoin.includes(ele.id)) {
				temp.push(ele);
			}
		});

		temp?.forEach((item, index) => {
			const randomUUID = uuidv4().replace(/-/g, ''); // Generate UUID and remove hyphens
			const numericValue = parseInt(randomUUID, 16)% 100000; // Convert UUID to a hexadecimal number

			temp[index] = {
				...temp[index],
				id: numericValue,
				question_id: numericValue,
				tempId: temp[index].id,
			};
		});

		if (tempData?.length > 0) {
			mainTemp = [...mainTemp, ...tempData];
		}
		if (temp?.length > 0) {
			mainTemp = [...mainTemp, ...temp];
		}
		mainTemp?.forEach((item) => {
			if (item?.tempId?.split('-')?.[item?.tempId?.split('-')?.length - 1] !== 'spacer') {
				if (!item.question_id) {
					mainTemp2.push({ ...item, id: getJoinData?.length + 1, question_id: getJoinData?.length + 1 });
				}
				else {
					mainTemp2.push({ ...item });
				}
			}
		});
		return mainTemp2;
	};

	const editQuestionHandler = (payload, company_id, id, booleanValue, isFormSubmit) => {
		const token = localStorage.getItem('access_token');
		dispatch({ type: actionType.LOADERSTART });
		api.put(`company-dashboard/${company_id}/site/${id}/upload-rulebased-data`, payload, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				dispatch({ type: actionType.LOADERSTOP });

				if (!booleanValue) {
					toast.success(<div>
						{res.data.message} <a href={res.data.url} target='_blank'>Link</a>
					</div>)
				}

				if (isFormSubmit) {
					let siteInfo = JSON.parse(localStorage.getItem("siteInfo"))
					
					navigate(`/generate-script/${siteInfo.websiteId}/${siteInfo.domainId}`)
				}

				if (res?.data?.status == true) {
					getQuestion(dispatch, company_id, id);
				}
			})
			.catch((err) => {
				dispatch({ type: actionType.LOADERSTOP });
				failedApiHandler(err, () => editQuestionAction(dispatch, payload, company_id, id));
			});
	}

	console.log('UAT PUSH 17 MAY');

	const deleteSelectCheckHandler = (id, data, cannotSave, deleteItemIndex, deleteItem) => {
		console.log("123123 deleteSelectCheckHandler working");
		let newArray = data;

		if (deleteItem) {
			newArray?.map(item => {
				let optionSelect = item?.optionSelect;
				optionSelect?.map(option => {
					option?.map(optionItem => {
						if (optionItem?.notVisible === id) {
							return delete optionItem.notVisible
						}
					})
				})
			})

			let filteredRecord = newArray?.filter(item => {
				return item.id === id
			})

			if (filteredRecord[0]?.type === 'Select') {
				const questionIdPresent = []

				let selectedOption = filteredRecord[0]?.optionSelect;
				selectedOption?.filter(item => {
					item?.map(option => {
						let filtered = newArray?.filter(filterItem => {
							return filterItem.question_id !== option.notVisible
						})

						newArray = filtered;

						questionIdPresent.push(option.notVisible)
					})
				})
			}

			newArray?.map(item => {
				let optionSelect = item.optionSelect;

				optionSelect?.splice(deleteItemIndex, 1)
			})

			// Removing the index from the list of options

			let k = newArray?.filter(item => {
				return item.id !== id
			})

			newArray = k;

		} else {
			newArray?.map(item => {
				let optionSelect = item.optionSelect;
				optionSelect?.map(option => {
					option?.map(optionItem => {
						if (optionItem.notVisible === id) {
							return delete optionItem.notVisible
						}
					})
				})
			})

			// Removing the index from the list of options

			// newArray?.map(item => {
			// 	let optionSelect = item.optionSelect;

			// 	optionSelect?.splice(deleteItemIndex, 1)
			// }) 
		}

		setJoinData(newArray)

		handleSave(newArray, true)
		// if (!cannotSave) {
		// }
	};

	useEffect(() => {
		if (questionData?.result?.length > 0) {
		}
	}, [getQuestionData]);

	console.log(questionData, 'question data');
	console.log(getJoinData, 'get join data');

	useEffect(() => {
		if (questionData?.result?.length > 0) {
			setJoinData(optionBlock(getJoinData?.length > 0 ? getJoinData : questionData?.result));
		} else {
			setJoinData(optionBlock(getJoinData));
		}
	}, [questionData, fields]);

	useEffect(() => {
		return () => {
			dispatch({ type: actionType.GET_QUESTION_DELETE });
		};
	}, []);

	useEffect(() => {
		let tempnew = [];
		getJoinData?.forEach((item) => {
			if (item.options) {
				item.options.forEach((ele) => {
					tempnew.push({ ...ele, qid: item.id });
				});
			}
		});
		setSelectOption(tempnew);
	}, [getJoinData]);

	const { attributes, listeners, setNodeRef, transform, transition } = useDroppable({
		id: 'canvas_droppable',
		data: {
			parent: null,
			isContainer: true,
		},
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	const handleSave = (fields, booleanValue, isFormSubmit) => {
		const fieldsData = [...fields];
		let payload = new FormData();
		payload.append('data', JSON.stringify(fieldsData));
		if (getQuestionData?.result?.length > 0) {
			// editQuestionAction(dispatch, payload, loginData?.result?.company_id, id);
			editQuestionHandler(payload, loginData?.result?.company_id, id, booleanValue, isFormSubmit)
		} else {
			// addQuestionAction(dispatch, payload, loginData?.result?.company_id, id);
			editQuestionHandler(payload, loginData?.result?.company_id, id, booleanValue, isFormSubmit)

		}
	};

	const moveCard = useCallback((dragIndex, hoverIndex) => {
		setJoinData((prevCards) =>
			update(prevCards, {
				$splice: [
					[dragIndex, 1],
					[hoverIndex, 0, prevCards[dragIndex]],
				],
			})
		);
	}, []);

	useEffect(() => {
		if (loginData?.result?.company_id) {
			getQuestion(dispatch, loginData?.result?.company_id, id);
		}
	}, [loginData]);


	return (
		<div ref={setNodeRef} className='drop-bottom' style={style} {...attributes} {...listeners}>
			<div className='canvas-fields'>
				<DndProvider backend={HTML5Backend}>
					{getJoinData?.map((f, i) => {
						return (
							<SortableField
								key={f.id}
								id={f.id}
								itemId={f.id}
								field={f}
								index={i}
								temptt={getJoinData}
								setFields={setJoinData}
								questionData={questionData}
								getJoinData={getJoinData}
								selectOption={selectOption}
								dragFields={dragFields}
								optionSelect={optionSelect}
								setOptionSelect={setOptionSelect}
								updateSelectList={updateSelectList}
								setUpdateSelectList={setUpdateSelectList}
								setCheck2={setCheck2}
								allOptionList={allOptionList}
								setAllOptionList={setAllOptionList}
								selectedOption={selectedOption}
								setSelectedOption={setSelectedOption}
								updateData={updateData}
								deleteSelectCheckHandler={deleteSelectCheckHandler}
								moveCard={moveCard}
							/>
						);
					})}
				</DndProvider>
			</div>
			<div className='btn-wrap'>

				<Button
					className='save-btn'
					color='primary'
					icon='Save'
					onClick={() => {
						handleSave(getJoinData, false, true);
					}}
					disabled={getJoinData?.length === 0 ? true : false}
				>
					Save
				</Button>

			</div>
		</div>
	);
}
