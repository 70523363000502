import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import USERS from '../../common/data/userDummyData';
import Avatar from '../../components/Avatar';
import Accordion, { AccordionItem } from '../../components/bootstrap/Accordion';
import Button from '../../components/bootstrap/Button';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../components/bootstrap/Card';
import Dropdown, {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Popovers from '../../components/bootstrap/Popovers';
import Icon from '../../components/icon/Icon';
import { Link, useNavigate } from 'react-router-dom';
import AddManageSite from './AddManageSite';
import { siteList } from '../../Store/Action';
import { useDispatch, useSelector } from 'react-redux';
import DeleteModal from './DeleteModal';
import PaginationButtons, { PER_COUNT } from '../../components/PaginationButtons2';


const ManageSite = () => {
    const [deletePopup, setDeletePopup] = useState(false)
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [currentPage, setCurrentPage] = useState(1);
    const siteListData = useSelector(state => state?.projectsReducer?.siteList);
    const loginData = useSelector(state => state?.projectsReducer?.loginData);
    const [editData, setEditData] = useState()
    const [upcomingEventsEditOffcanvas, setUpcomingEventsEditOffcanvas] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    //add-new
    const handleAddNew = () => {
        setUpcomingEventsEditOffcanvas(!upcomingEventsEditOffcanvas);
    };
    //get site_Data
    // useEffect(() => {
    //     if (loginData?.result?.company_id) {
    //         siteList(dispatch, loginData?.result?.company_id, navigate, currentPage,
    //             perPage)
    //     }
    // }, [loginData])

    useEffect(() => {
        if (perPage && currentPage && loginData?.result?.company_id) {
            siteList(
                dispatch,
                loginData?.result?.company_id,
                currentPage,
                perPage
            );
        }
    }, [perPage, currentPage, loginData]);


    //edit
    const handleEdit = (web_id) => {
        setUpcomingEventsEditOffcanvas(!upcomingEventsEditOffcanvas)
        setEditData(web_id)
    }
    //delete
    const handleDelete = (website_id) => {
        setDeletePopup(website_id)
    }
    return (
        <PageWrapper title={"Open ChatBot"}>
            <Page>
                <Card stretch data-tour='list'>
                    <CardHeader>
                        <CardLabel icon='Rule' iconColor='info'>
                            <CardTitle>Manage Site</CardTitle>
                        </CardLabel>
                        <CardActions>
                            {/* <Button
                                color='info'
                                icon='Add'
                                isLight
                                onClick={handleAddNew}>
                                Add new
                            </Button> */}
                        </CardActions>
                    </CardHeader>
                    <CardBody className='table-responsive role-table manage-site-table' isScrollable>
                        {siteListData?.result?.length > 0 ? <table className='table table-modern table-hover'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th scope='col'>Bot Name</th>
                                    <th scope='col'>Domain</th>
                                    <th scope='col'>Category</th>
                                    <th scope='col'>Created At</th>
                                    <th scope='col' className='text-end'>
                                        Actions  
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {siteListData?.result?.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <th scope='row'>{item.id}</th>
                                            <td scope='row'>
                                                <Icon
                                                    icon='Folder'
                                                    size='2x'
                                                    color='primary'
                                                    className='file-icon'
                                                />
                                                {item?.website_name}
                                            </td>
                                            <td scope='row'>{item?.domain}</td>
                                            <td>{item.category}</td>
                                            <td>{item.created_at}</td>
                                            <th scope='row' className='text-end'>
                                                <CardActions>
                                                    <Dropdown className='d-inline'>
                                                        <DropdownToggle hasIcon={false}>
                                                            <Button icon='MoreHoriz' />
                                                        </DropdownToggle>
                                                        <DropdownMenu isAlignmentEnd>
                                                            <DropdownItem>
                                                                <Button icon='Edit' onClick={() => handleEdit(item)}>Edit</Button>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <Link to={`/build/design/${item?.site_uidetails?.domainui_id}`}> <Icon icon="chat" />Customize chat widget</Link>
                                                            </DropdownItem>
                                                            <DropdownItem>
                                                                <Button icon='Delete' onClick={() => handleDelete(item?.website_id)}>Delete</Button>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </CardActions>
                                            </th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> :
                            <div className='no-data-found'>No Data Found</div>
                        }
                        <DeleteModal currentPage={currentPage} perPage={perPage} deletePopup={deletePopup} setDeletePopup={setDeletePopup} />
                        {siteListData?.result?.length > 0 && <PaginationButtons
                            data={siteListData?.all_data_count}
                            label='customers'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}

                        />}
                    </CardBody>

                    

                </Card>
                <AddManageSite setEditData={setEditData} currentPage={currentPage} perPage={perPage} setUpcomingEventsEditOffcanvas={setUpcomingEventsEditOffcanvas} upcomingEventsEditOffcanvas={upcomingEventsEditOffcanvas} editData={editData} />
            </Page>
        </PageWrapper >
    );
};

export default ManageSite;
