import { Navigate } from 'react-router-dom';

function ProtectContentWrapper({ children }) {
    let localItem = localStorage.getItem('access_token');

    if (localItem) {
        return children;
    } else {
        return <Navigate to={'/'} />;
    }
}

export default ProtectContentWrapper;