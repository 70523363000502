import React, { useState } from 'react';
import classNames from 'classnames';
import Icon from '../../../components/icon/Icon';
import { priceFormat } from '../../../helpers/helpers';
import Progress from '../../../components/bootstrap/Progress';
import { CardBody } from '../../../components/bootstrap/Card';
import SERVICES from '../../../common/data/serviceDummyData';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';

const CommonServicesList = ({data, headers, show}) => {

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['5']);
	const { items, requestSort, getClassNamesFor } = useSortableData(data);
	return (
		<>
			<CardBody className='table-responsive'>
				<table className='table table-modern table-hover'>
					<thead>
						<tr>
							{headers.map(item => (
								<th
								onClick={() => requestSort(item.accessors)}
								className='cursor-pointer'>
								{item.name}{' '}
								<Icon
									size='lg'
									className={getClassNamesFor(item.accessors)}
									icon='FilterList'
								/>
							</th>
							))}
						</tr>
					</thead>
					<tbody>
						{dataPagination(items, currentPage, perPage).map((item) => (
							show(item)
						))}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				data={items}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
			/>
		</>
	);
};

export default CommonServicesList;
