import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../assets/img/open-chat.png';
import useDarkMode from '../../../hooks/useDarkMode';

// eslint-disable-next-line react/prop-types
const ForgetPassswordHeader = ({ isNewUser }) => {

    if (isNewUser) {
        return (
            <>
                <div className='text-center h1 fw-bold mt-5'>Create Account,</div>
                <div className='text-center h4 text-muted mb-3 mt-3'>Sign up to get started!</div>
            </>
        );
    }
    return (
        <>
            {/* <div className='text-center h1 fw-bold mt-5'>Welcome,</div> */}
            <div className='text-center  continue-signin'>Reset Password</div>
        </>
    );
};

const ForgetPasssword = ({ isSignUp }) => {
    const [otp, setOtp] = useState(false)

    const { darkModeStatus } = useDarkMode();

    const [usernameInput, setUsernameInput] = useState(false);
    const [isNewUser, setIsNewUser] = useState(isSignUp);

    useEffect(() => {
        setIsNewUser(isSignUp)
    }, [isSignUp])

    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/'), [navigate]);
    const handleOtp = () => {
        setOtp(true)
    }
    return (
        <PageWrapper
            title={isNewUser ? 'Sign Up' : 'Login'}
            className={classNames({ 'bg-signup': !isNewUser, 'bg-info': !!isNewUser })}>
            <Page className='p-0'>
                <div className='row h-100 align-items-center justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                        <Card className='shadow-3d-dark signup-main-wrap' data-tour='login-page'>
                            <CardBody>
                                <div className='text-center'>
                                    <Link
                                        to='/'
                                        className={classNames(
                                            'text-decoration-none  fw-bold ',
                                            {
                                                'text-dark': !darkModeStatus,
                                                'text-light': darkModeStatus,
                                            },
                                        )}>
                                        <div className='login-logo-wrp'>
                                            <img src={Logo} alt="logo" />
                                        </div>
                                    </Link>
                                </div>


                                <ForgetPassswordHeader isNewUser={isNewUser} />

                                <form className='row g-4'>
                                    {isNewUser ? (
                                        <>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='signup-email'
                                                    isFloating
                                                    label='Your email'>
                                                    <Input type='email' autoComplete='email' />
                                                </FormGroup>
                                            </div>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='signup-name'
                                                    isFloating
                                                    label='Your name'>
                                                    <Input autoComplete='given-name' />
                                                </FormGroup>
                                            </div>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='signup-surname'
                                                    isFloating
                                                    label='Your surname'>
                                                    <Input autoComplete='family-name' />
                                                </FormGroup>
                                            </div>
                                            <div className='col-12'>
                                                <FormGroup
                                                    id='signup-password'
                                                    isFloating
                                                    label='Password'>
                                                    <Input
                                                        type='password'
                                                        autoComplete='password'
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-12'>
                                                <Button
                                                    color='info'
                                                    className='w-100 py-3'
                                                    onClick={handleOnClick}>
                                                    Sign Up
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='col-12'>
                                                <label className='label'>Username/Email</label>
                                                <FormGroup
                                                    id='login-username'
                                                >
                                                    <Input autoComplete='username' className="form-control"
                                                    />
                                                </FormGroup>

                                            </div>
                                            {
                                                otp && <div className='col-12'>
                                                    <label className='label'>Enter OTP</label>
                                                    <FormGroup
                                                        id='login-password'
                                                    >
                                                        <Input
                                                            type='number'
                                                            autoComplete='password'
                                                            className="form-control"

                                                        />
                                                    </FormGroup>
                                                </div>
                                            }

                                            <div className='col-12'>
                                                <Button className='w-100 py-3 btn-continue btn' onClick={handleOtp} color='warning'>
                                                    Send OTP
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </CardBody>
                        </Card>
                        <div className='text-center'>
                            <a
                                href='/'
                                className={classNames('text-decoration-none me-3', {
                                    'link-light': isNewUser,
                                    'link-dark': !isNewUser,
                                })}>
                                Privacy policy
                            </a>
                            <a
                                href='/'
                                className={classNames('link-light text-decoration-none', {
                                    'link-light': isNewUser,
                                    'link-dark': !isNewUser,
                                })}>
                                Terms of use
                            </a>
                        </div>
                    </div>
                </div>
            </Page>
        </PageWrapper >
    );
};
ForgetPasssword.propTypes = {
    isSignUp: PropTypes.bool,
};
ForgetPasssword.defaultProps = {
    isSignUp: false,
};

export default ForgetPasssword;
