import React from 'react'
import ManageImage from './ManageImage'
import ManageText from './ManageText'

const WelcomeTheme = ({ setCustomAvatarRight, setCustomPreviewUpload, setCustomWelcomeImageId, customWelcomeImageId, setCustomWelcomeText, customWelcomeText, avatarList, setCustomPreview, customPreview }) => {
    return (
        <div className='welcome-theme'>
            <ManageText setCustomWelcomeText={setCustomWelcomeText} customWelcomeText={customWelcomeText} />
        </div>
    )
}

export default WelcomeTheme