import React from 'react'

const Position = ({ setPosition, position }) => {
    const handlePosition = (e) => {
        console.log(e)
        setPosition(e)
    }
    return (
        <div className='position-wrp theme-head'>
            <h3 onDrag={() => console.log("draggged")} onDragEnd={() => console.log("leave")}>Position
            </h3>
            <div className='position-select'>
                <div className='box-wrap'>
                    <input type="radio" name="position" className='form-control' onClick={(e) => handlePosition("BL")} />
                    <div className={`square-box ${position == "BL" ? "active-position" : ""}`}>
                    </div>
                    <span className='square bl'></span>
                </div>
                <div className='box-wrap'>
                    <input type="radio" name="position" className='form-control' onClick={(e) => handlePosition("VRC")} />
                    <div className={`square-box ${position == "VRC" ? "active-position" : ""}`}>

                    </div>
                    <span className='square vrc'></span>
                </div>
                <div className='box-wrap'>
                    <input type="radio" name="position" className='form-control' onClick={(e) => handlePosition("BR")} />
                    <div className={`square-box ${position == "BR" ? "active-position" : ""}`}>

                    </div>
                    <span className='square br'></span>
                </div>
            </div>

        </div>
    )
}

export default Position