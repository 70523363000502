import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
// import Logo from '../../../assets/img/open-chat.png';
import Logo from '../../../assets/img/Final-logo.png';
import useDarkMode from '../../../hooks/useDarkMode';
import { api } from '../../../Api/Index';
import { useDispatch, useSelector } from 'react-redux';
import { loginAction } from '../../../Store/Action';
import toast from 'react-hot-toast';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = ({ isSignUp }) => {
	const dispatch = useDispatch();
	const loginDAta = useSelector((state) => state.projectsReducer.loginData);
	const { darkModeStatus } = useDarkMode();
	const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [email, setEmail] = useState();
	const [passWord, setPassWord] = useState();
	const [surName, setSurName] = useState();
	const [name, setName] = useState();
	const [userError, setUserError] = useState({
		email: false,
		passwordLength: false,
		emailLength: false,
	});

	const notify = (message) =>
		toast(message, {
			style: {
				background: 'green',
				color: '#fff',
			},
		});
	useEffect(() => {
		setIsNewUser(isSignUp);
	}, [isSignUp]);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);
	const emailHandler = (e) => {
		setEmail(e.target.value);
		setUserError({ ...userError, emailLength: false, email: false });
	};
	const passWordHandler = (e) => {
		setPassWord(e.target.value);
		setUserError({ ...userError, passwordLength: false });
	};



	const [showPassword, setShowPassword] = useState(false);

	const loginHandler = () => {



		const payload = { email: email, password: passWord };
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		if (!email || email.trim() === '') {
			setUserError({ ...userError, emailLength: true });
			return
		}
		else if (!emailRegex.test(email)) {
			setUserError({ ...userError, email: true });
			return;
		}
		
		if (!passWord || passWord.trim() === '') {
			setUserError({ ...userError, passwordLength: true });
		}

		if (email && passWord) {
			loginAction(dispatch, payload, navigate, notify);
		}
	};

	const LoginHeader = () => {
		return (
			<>
				<div className='text-center  continue-signin'>Sign in to continue!</div>
			</>
		);
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<PageWrapper
			title={isNewUser ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-signup': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark signup-main-wrap' data-tour='login-page'>
							<CardBody>
								<div className='text-center'>
									<Link
										to='/'
										className={classNames('text-decoration-none  fw-bold ', {
											'text-dark': !darkModeStatus,
											'text-light': darkModeStatus,
										})}>
										<div className='login-logo-wrp'>
											<img src={Logo} alt='logo' />
										</div>
									</Link>
								</div>
								<LoginHeader isNewUser={isNewUser} />
								<form className='row g-4'>
									<>
										<div className='col-12'>
											<label className='label'>Email ID</label>
											<FormGroup id='login-username'>
												<Input
												type='email'
													autoComplete='username'
													className='form-control'
													onChange={(e) => emailHandler(e)}
													placeholder='Email ID'
												/>
											</FormGroup>
											{userError.emailLength && (
												<p className='error'>
													{' '}
													Please enter valid email
												</p>
											)}
											{userError.email && <p className='error'>Please enter valid email</p>}
										</div>
										<div className='col-12'>
											<label className='label'>Password</label>
											<FormGroup id='login-password' className='position-relative'>
												<>
													<Input
														type={showPassword ? 'text' : 'password'}
														autoComplete='password'
														className='form-control'
														onChange={(e) => passWordHandler(e)}
														placeholder='Password'
													/>
													<button
													    className='eye-btn'
														type='button'
														onClick={togglePasswordVisibility}>
														{showPassword ? <FaEye /> : <FaEyeSlash />}
													</button>
												</>
											</FormGroup>
											{userError.passwordLength && (
												<p className='error'>Please enter password</p>
											)}
										</div>
										{/* <div className='form-btn-block d-flex justify-content-between'> */}

										
										<div to='/' className='forget-password'>
											<Link to='/forget-password'>Forget Password</Link>
										</div>

										{/* </div> */}
										<div className='col-12'>
											{!usernameInput ? (
												<Button
													color='warning '
													className='w-100 py-3 btn-continue btn'
													onClick={loginHandler}>
													Continue
												</Button>
											) : (
												<Link
													to='/dashboard'
													color='warning '
													className='w-100 py-3 btn-continue btn'>
													Login
												</Link>
											)}
										</div>

										<div to='/' className='sign-up-btn form-btn-block d-flex'>
											<span>Don't have an account?</span>
											<Link to='/sign-up'>Sign Up</Link>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
