import Multiselect from 'multiselect-react-dropdown'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../../../components/bootstrap/Button'
import Card from '../../../components/bootstrap/Card'
import Icon from '../../../components/icon/Icon'
import Page from '../../../layout/Page/Page'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import { getCategoryAction } from '../../../Store/Action'

const CustomizeBot = () => {
    const navigate = useNavigate()
    const loginData = useSelector(state => state?.projectsReducer?.loginData)
    const categoryData = useSelector(state => state?.projectsReducer?.categoryData)
    const [selectedOrganization, setSelectedOrganization] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        getCategoryAction(dispatch, loginData?.result?.company_id)
    }, [loginData])

    console.log("selectedOrganization", selectedOrganization)
    const handleSubmit = () => {
        navigate(`/build/design/${selectedOrganization?.[0]?.site_uidetails?.domainui_id}`)
    }
    return (
        <PageWrapper title={'Script'}>
            <Page>
                <div className='row'>
                    <div className='col-12 customize-main-wrp'>
                        <Card stretch data-tour='list'>
                            <div className='customize-chat'>
                                <h3>Choose Organization</h3>

                                <div className='single-select '>
                                    <label className='form-label'>Select Organization</label>
                                    {categoryData?.result?.length > 0 && <Multiselect
                                        options={categoryData?.result}
                                        displayValue="website_name"
                                        showArrow={true}
                                        showCheckbox={true}
                                        singleSelect={true}
                                        onSelect={(e) => setSelectedOrganization(e)}
                                    />}
                                </div>
                                <Button
                                    color='info'
                                    icon='Save'
                                    isLight
                                    className="save-btn"
                                    disabled={!selectedOrganization?.[0]?.site_uidetails?.id}
                                    onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </div>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    )
}

export default CustomizeBot