import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import avtar from '../../assets/img/human.svg';
import Icon from '../../components/icon/Icon';
import { getCompanyDetail } from '../../Store/Action';
const AccountDetail = () => {
    const dispatch = useDispatch()
    const loginData = useSelector(state => state?.projectsReducer?.loginData)
    const company_deatil = useSelector(state => state?.projectsReducer?.companyDetail)

    const [avatarSrc, setAvatarSrc] = useState("")

    useEffect(()=> {
        const companyLogo = company_deatil?.result?.company_logo;
        if (companyLogo) {
            setLogoSrc(companyLogo);
        }
    }, [company_deatil?.result?.company_logo])

    return (
        <div className='account-detail account-detail-wrp'>
            <form>
                <div className='profile-icon'>
                    <img src={avatarSrc} onError={(event)=> {
                        setAvatarSrc("https://cdn.iconscout.com/icon/free/png-256/free-avatar-372-456324.png")
                    }} alt="avtar" />
                </div>
                <div className='contact-information row'>
                    <div className='form-group col-6'>
                        <label>Company Name : </label>
                        <div className='form-value'>{company_deatil?.result?.name}</div>
                    </div>

                    <div className='form-group link col-6'>
                        <label>Email ID : </label>
                        <Link href="mailto:someone@example.com" className='link-text'>
                            {company_deatil?.result?.email}
                        </Link>
                    </div>
                    <div className='form-group col-6'>
                        <label>Phone Number : </label>
                        <div className='form-value'>{company_deatil?.result?.phone_number}</div>
                    </div>

                    <div className='form-group col-6'>
                        <label>Plan Name : </label>
                        <div className='change-plan'>
                            <div className='form-value'>{company_deatil?.result?.plan_name}</div>
                            <Link to="/change-paln" className='change-plan'>
                                Change Plan
                                <Icon icon='edit' size='lg' />
                            </Link>
                        </div>
                    </div>
                    {/* <div className='form-group col-6'>
                        <label>Activation Date : </label>
                        <div className='form-value'>{company_deatil?.result?.start_date}</div>
                    </div> */}
                    {/* <div className='form-group col-6'>
                        <label>Expiry Date : </label>
                        <div className='form-value'>{company_deatil?.result?.expire_date}</div>
                    </div> */}
                    {/* <div className='form-group col-6'>
                        <label>Next Renewal Date : </label>
                        <div className='form-value'>{company_deatil?.result?.renewal_date}</div>
                    </div> */}
                    {/* <div className='form-group col-6'>
                        <label>Site Limit : </label>
                        <div className='form-value'>{company_deatil?.result?.site_limit}</div>
                    </div> */}
                </div>
            </form>
        </div>


    )
}

export default AccountDetail