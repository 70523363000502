import { navigate } from '@storybook/addon-links';
import { api } from '../Api/Index';
import { actionType } from './ActionType';
//login


	// =====================Auto Logout===========================================================


function failedApiHandler(error, apiCaller) {
	let { status, statusText } = error.response;

	let refresh_token = localStorage.getItem("refresh_token");

	if (!refresh_token) {
		localStorage.clear();
		window.location.href = '/';
		navigate('/');
	}

	if (status === 401 || statusText === 'Unauthorized') {
		// Request for a refresh token
		api.get("company-dashboard/user-refresh-token/", {
			headers: {
				Authorization: `Bearer ${refresh_token}`,
			},
		}).then(response => {
			localStorage.setItem("access_token", response.data.access_token)
			localStorage.setItem("refresh_token", response.data.refresh_token)
			apiCaller()
		})
			.catch(error => {
				window.location.href = '/';
				navigate('/');
				localStorage.clear();
			})
	}
}

	// =====================Auto Logout===========================================================


export const loginAction = (dispatch, payload, navigate, notify) => {
	dispatch({ type: actionType.LOADERSTART });
	api.post('company-dashboard/login', payload)
		.then((res) => {
			notify(res?.data?.message);
			dispatch({ type: actionType.LOGIN, payload: res?.data });
			if (res?.data?.status == true) {
				localStorage.setItem('access_token', res?.data?.access_token);
				localStorage.setItem("refresh_token", res?.data?.refresh_token);
				localStorage.setItem('company_id', res?.data?.result?.company_id);
				navigate('/dashboard');
			}
		})
		.catch((err) => dispatch({ type: actionType.LOADERSTOP }));
};

// Sign up
export const signUpAction = (dispatch, payload, navigate, notify) => {
	dispatch({ type: actionType.LOADERSTART });
	api.post('company-dashboard/company', payload)
		.then((res) => {
			notify(res?.data?.message);
			dispatch({ type: actionType.LOGIN, payload: res?.data });
			if (res?.data?.status == true) {
				loginAction(dispatch, payload, navigate, notify);
			}
		})
		.catch((err) => dispatch({ type: actionType.LOADERSTOP }));
};

//logout
export const handleLogout = (dispatch, payload, notify, navigate) => {
	dispatch({ type: actionType.LOADERSTART });
	api.post('company-dashboard/logout', payload, {
		headers: {
			Authorization: `Bearer ${payload}`,
		},
	})
		.then((res) => {
			localStorage.removeItem('access_token');
			localStorage.removeItem('company_id');
			notify(res?.data?.message);
			dispatch({ type: actionType.LOGIN, payload: null });
			navigate('/');
		})
		.catch((error) => {
			failedApiHandler(error, ()=> handleLogout(dispatch, payload, notify, navigate));
			dispatch({ type: actionType.LOADERSTOP });
		});
};
//site-list
export const siteList = (dispatch, payload, page, size) => {
	dispatch({ type: actionType.LOADERSTART });

	const token = localStorage.getItem('access_token');
	api.get(`company-dashboard/${payload}/site?page=${page}&size=${size}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.SITE_LIST, payload: res?.data });
			if (res?.data?.message?.errors?.[0] === 'Unauthenticated') {
				localStorage.removeItem('access_token');
				localStorage.removeItem('company_id');
				window.location = '/';
			}
		})
		.catch((e) => {
			failedApiHandler(e, () => siteList(dispatch, payload, page, size));
			if (e?.response?.data?.message?.errors?.[0] === 'Unauthenticated') {
				localStorage.removeItem('access_token');
				localStorage.removeItem('company_id');
				window.location = '/';
			}
			dispatch({ type: actionType.LOADERSTOP });
		});
};

//delete-site
export const handleDeleteAction = (
	dispatch,
	payload,
	company_id,
	setDeletePopup,
	notify,
	currentPage,
	perPage,
) => {
	dispatch({ type: actionType.LOADERSTART });
	const token = localStorage.getItem('access_token');
	api.delete(`company-dashboard/${company_id}/site/${payload}/`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			console.log('website_id');
			siteList(dispatch, company_id, currentPage, perPage);
			setDeletePopup(false);
			notify(res?.data?.message);
		})
		.catch((e) => {
			failedApiHandler(e, ()=> handleDeleteAction(dispatch, payload, company_id, setDeletePopup, notify, currentPage, perPage));
			dispatch({ type: actionType.LOADERSTOP });
		});
};
//add-site
export const addSite = (
	setUser,
	dispatch,
	payload,
	setUpcomingEventsEditOffcanvas,
	notify,
	currentPage,
	perPage,
) => {
	dispatch({ type: actionType.LOADERSTART });
	const token = localStorage.getItem('access_token');
	api.post(`company-dashboard/${payload.company_id}/site`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.ADD_SITE, payload: res?.data });
			setUpcomingEventsEditOffcanvas(false);
			siteList(dispatch, payload?.company_id, currentPage, perPage);
			setUser({
				sitename: '',
				domain: '',
				category: '',
			});
			notify(res?.data?.message);
		})
		.catch((e) => {
			failedApiHandler(e, ()=> addSite(setUser,
				dispatch,
				payload,
				setUpcomingEventsEditOffcanvas,
				notify,
				currentPage,
				perPage));
			dispatch({ type: actionType.LOADERSTOP });
		});
};
//edit-site
export const editSite = (
	setUser,
	dispatch,
	payload,
	company_id,
	setUpcomingEventsEditOffcanvas,
	website_id,
	notify,
	currentPage,
	perPage,
	setEditData,
) => {
	dispatch({ type: actionType.LOADERSTART });
	console.log('payload-edit', payload);
	const token = localStorage.getItem('access_token');
	api.put(`company-dashboard/${company_id}/site/${website_id}/`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.EDIT_SITE, payload: res?.data }); //not needed
			setUpcomingEventsEditOffcanvas(false);
			siteList(dispatch, company_id, currentPage, perPage);
			setUser({
				sitename: '',
				domain: '',
				category: '',
			});
			setEditData();
			notify(res?.data?.message);
		})
		.catch((e) => {
			failedApiHandler(e, ()=> editSite(setUser,
				dispatch,
				payload,
				company_id,
				setUpcomingEventsEditOffcanvas,
				website_id,
				notify,
				currentPage,
				perPage,
				setEditData,));
			dispatch({ type: actionType.LOADERSTOP });
		});
};

//company-detail

export const getCompanyDetail = (dispatch, payload) => {
	dispatch({ type: actionType.LOADERSTART });
	const token = localStorage.getItem('access_token');

	api.get(`company-dashboard/company/${payload}/`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.COMPANY_DETAIL, payload: res?.data });
		})
		.catch((error) => {
			failedApiHandler(error, () => getCompanyDetail(dispatch, payload));

			dispatch({ type: actionType.LOADERSTOP });
		});
};

//edit_company
export const handleEditAction = (dispatch, payload, id, setOpenEditForm) => {
	dispatch({ type: actionType.LOADERSTART });
	const token = localStorage.getItem('access_token');
	api.put(`company-dashboard/company/${id}/`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			getCompanyDetail(dispatch, id);
			setOpenEditForm(false);
		})
		.catch((e) => {
			failedApiHandler(e, ()=> handleEditAction(dispatch, payload, id, setOpenEditForm));
			dispatch({ type: actionType.LOADERSTOP });
		});
};
//getCategory
export const getCategoryAction = (dispatch, customer_id) => {
	dispatch({ type: actionType.LOADERSTART });
	const token = localStorage.getItem('access_token');
	api.get(`company-dashboard/${customer_id}/site`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.CATEGORY_DATA, payload: res?.data });
		})
		.catch((e) => {
			failedApiHandler(e, ()=> getCategoryAction(dispatch, customer_id));
			dispatch({ type: actionType.LOADERSTOP });
		});
};
// export const getCategoryAction = (dispatch, customer_id) => {
//     dispatch({ type: actionType.LOADERSTART })
//     const token = localStorage.getItem("access_token")
//     api.get(`company-dashboard/${customer_id}/category`, {
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//     }).then((res) => {
//         dispatch({ type: actionType.CATEGORY_DATA, payload: res?.data })

//     }).catch((e) => {
//         dispatch({ type: actionType.LOADERSTOP })
//     })
// }

//Custom Design
export const designAction = (dispatch, payload, company_id, navigate, wesite_id) => {
	const token = localStorage.getItem('access_token');
	api.post(`company-dashboard/${company_id}/site/123/domain_ui`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.DESIGN_DATA, payload: res?.data });
			navigate(`/generate-script/${wesite_id}`);
		})
		.catch((e) => {
			failedApiHandler(e, ()=> designAction(dispatch, payload, company_id, navigate, wesite_id));
		});
};

export const designEditAction = (
	dispatch,
	payload,
	company_id,
	id,
	callback,
	navigate,
	wesite_id,
) => {
	const token = localStorage.getItem('access_token');
	api.put(`company-dashboard/${company_id}/site/123/domain_ui/${id}/`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.DESIGN_DATA, payload: res?.data });
			callback();
			let siteInfo = {
				websiteId: wesite_id,
				domainId: res?.data?.result?.domainui_id
			}

			localStorage.setItem("siteInfo", JSON.stringify(siteInfo))
			
			navigate(`/train-modal/${wesite_id}`);
		})
		.catch((e) => {
			failedApiHandler(e, ()=> designEditAction(dispatch, payload, company_id, id, callback, navigate, wesite_id));
		});
};

export const getAvatarListAction = (dispatch, company_id) => {
	console.log('company_id', company_id);
	const token = localStorage.getItem('access_token');
	api.get(`/company-dashboard/${company_id}/avatar`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.AVATAR_LIST, payload: res?.data });
		})
		.catch((e) => {
			failedApiHandler(e, ()=> getAvatarListAction(dispatch, company_id));
		});
};

export const UploadAvatarImageAction = (dispatch, payload, company_id) => {
	const token = localStorage.getItem('access_token');
	console.log('tokennnn', token);
	api.post(`/company-dashboard/${company_id}/avatar`, payload, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.AVATAR_LIST, payload: res?.data });
		})
		.catch((e) => {
			failedApiHandler(e, ()=> UploadAvatarImageAction(dispatch, payload, company_id));
		});
};

// getDesignData

export const getDesignData = (dispatch, company_id, id) => {
	console.log('new--', company_id);
	const token = localStorage.getItem('access_token');
	api.get(`company-dashboard/${company_id}/site/123/domain_ui/${id}/`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => {
			dispatch({ type: actionType.GET_DESIGN, payload: res?.data });
		})
		.catch((e) => {
			failedApiHandler(e, ()=> getDesignData(dispatch, company_id, id));
		});
};

// export const getDesignDataAction = (dispatch, company_id,domain_id) => {
//     console.log("new--", domain_id)

// if(domain_id){
//     const token = localStorage.getItem("access_token")
//     api.get(`company-dashboard/${company_id}/site/123/domain_ui/${domain_id}/`, {
//         headers: {
//             Authorization: `Bearer ${token}`,
//         },
//     }).then((res) => {
//         dispatch({ type: actionType.GET_DESIGN, payload: res?.data })

//     }).catch((e) => {
//         console.log(e)
//     })
// }
// }
// 2222222222222
// export const domainuiIdAction = (dispatch, id) => {

//         dispatch({
//             type: actionType.GET_DOMAIN_ID,
//             payload: id
//         });

// };
