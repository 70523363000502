import React from 'react';
import PropTypes from 'prop-types';
import logoImage from "../assets/img/logo.png"

const Logo = ({ width, height }) => {
	return (
		<img src={logoImage} alt='logo'/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
