export const actionType = {
    LOGIN: "LOGIN",
    ADD_SITE: "ADD_SITE",
    SITE_LIST: "SITE_LIST",
    EDIT_SITE: "EDIT_SITE",
    COMPANY_DETAIL: "COMPANY_DETAIL",
    CATEGORY_DATA: "CATEGORY_DATA",
    LOADERSTART: "LOADERSTART",
    LOADERSTOP: "LOADERSTOP",
    DESIGN_DATA: "DESIGN_DATA",
    AVATAR_LIST: "AVATAR_LIST",
    GET_DESIGN: "GET_DESIGN",
    ADD_QUESTION: "ADD_QUESTION",
    GET_QUESTION: "GET_QUESTION",
    GET_QUESTION_DELETE: "GET_QUESTION_DELETE",
    OPTION_LIST:"OPTION_LIST",
    // GET_DOMAIN_ID:"GET_DOMAIN_ID"
}